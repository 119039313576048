import React from "react";
import axios from "axios";
import moment from "moment";
import config from "config.js"
import { useSelector } from 'react-redux';
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Collapse from "@material-ui/core/Collapse";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Paper from "@material-ui/core/Paper";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import BounceLoader from "react-spinners/BounceLoader";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import Table from "components/Table/Table.js";
import MaterialTable from "material-table";
import Details from "./Components/Details.js"
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";


import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import sweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle.js";


const override = css`
display: block;
margin: 32px auto;b
border-color: red;
`;


const accountBalanceCSS = css`
display: block;
margin: 0px 25px;
`;

const useStyles = makeStyles((theme) => ({
    ...sweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "left",
        display: "inlineBlock",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),

}));




function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TableList() {
    const classes = useStyles();
    const theme = useTheme();
    const user = useSelector(state => state.user);
    const [transactions, setTransactions] = React.useState([]);
    const [successTransactions, setSuccessTransactions] = React.useState([]);
    const [failedTransactions, setFailedTransactions] = React.useState([]);
    const [pendingTransaction, setPendingTransaction] = React.useState([]);
    const [InitiatedTransaction, setInitatedTransaction] = React.useState([]);

    const [showTransactionTable, setShowTransactionTable] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(true);
    const [csvData, setCsvData] = React.useState([]);

    const [respAlert, setRespAlert] = React.useState(null);
    const [submitting, setSubmitting] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [showDetails, setShowDetails] = React.useState(false);

    const [transactionDetails, setTransactionDetails] = React.useState(null);

    const [searchedKey, setSearchedKey] = React.useState("BFC Wallet");
    const [txnType, setTxnType] = React.useState("Internal-Transfer");
    const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
    const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));


    // const [accountBalance, setAccountBalance] = React.useState("");
    // // const [walletType, setWalletType] = React.useState("");
    // const [walletNo, setWalletNo] = React.useState("");
    // const [employerName, setEmployerName] = React.useState("");
    // const [userName, setUserName] = React.useState("");
    // const [userDetails, setUserDetails] = React.useState([]);
    // const [detailsModal, setDetailsModal] = React.useState(false);


    // const [openPassword, setOpenPassword] = React.useState(false);
    // const [submittingDeposit, setSubmittingDeposit] = React.useState(false);
    // const [password, setPassword] = React.useState("");
    // const [transactionID, setTransactionID] = React.useState("");
    // const [totalRecords, setTotalRecords] = React.useState("");

    const [value, setValue] = React.useState(0);

    const [page, setPage] = React.useState(0); // Page index starts from 0
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    React.useEffect(() => {
        searchTransactions(page, rowsPerPage);
    }, [page, rowsPerPage]);


    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }

    const validateSearch = () => {
        //function validate search params
        let snackBarMsg = ""
        let flag = true
        if (fromDate.length == 0) {
            flag = false
            snackBarMsg = "Please select from date."
        }
        if (toDate.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select To date." : snackBarMsg);
        }
        if (txnType.length == 0) {
            flag = false
            snackBarMsg = (snackBarMsg == "" ? "Please select type." : snackBarMsg);
        }
        if (!flag) {
            openSnackBar(snackBarMsg)
        }
        return flag
    }

    // const searchTransactions = () => {
    //     //function to search customer
    //     if (validateSearch()) {
    //         let data = {
    //             "mode": searchKey,
    //             "type": "SADAD_PAYMENT",
    //             "date1": moment(fromDate).format("MM-DD-YYYY"),
    //             "date2": moment(toDate).format("MM-DD-YYYY")
    //         }
    //         setSubmitting(true)
    //         axios.post(`${config.url().baseURL}/get-remittance-transactions-by-date`, data).then((resp) => {
    //             setSubmitting(false)
    //             let resData = resp.data;
    //             if (!resData.err) {
    //                 let respData = resp.data.data;
    //                 let failedData = []
    //                 let successData = []
    //                 let failedToAcknowledgeData = []
    //                 setSearchedKey(searchKey)
    //                 // console.log(JSON.stringify(respData))
    //                 var updatedData = respData.map((tran) => {
    //                     let date = tran.time
    //                     tran.date = `${moment(date).format("DD/MM/YYYY")} ${moment(date).format("h:mm:ss A")}`;
    //                     if (tran.status) {
    //                         tran.convertedStatus = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Success" />;
    //                     } else {
    //                         tran.convertedStatus = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Failure" />;
    //                     }
    //                     tran.dateOfCreation = moment(tran.creation_Date).format("DD/MM/YYYY");
    //                     if (tran.acknowledgeStatus) {
    //                         tran.ackStatusUpdated = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="Success" />;
    //                     } else {
    //                         tran.ackStatusUpdated = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="Failure" />;
    //                     }
    //                     tran.UserID = tran.userID
    //                     if (tran.status && tran.acknowledgeStatus) {
    //                         successData.push(tran)
    //                     } else if (tran.status && !tran.acknowledgeStatus) {
    //                         failedToAcknowledgeData.push(tran)
    //                     } else {
    //                         failedData.push(tran)
    //                     }
    //                     tran.updatedTransactionID = tran.transactionID;
    //                     return tran;
    //                 });

    //                 setFailedToAcknowledge(failedToAcknowledgeData)
    //                 setSuccessTransactions(successData)
    //                 setFailedTransactions(failedData)
    //                 setTransactions(updatedData);
    //                 setShowLoader(false);
    //                 setShowTransactionTable(true);

    //                 var updatedCsvData = respData.map((tran) => {
    //                     let temp = {};
    //                     temp.Date = tran.date;
    //                     temp.CPR = tran.cpr;
    //                     temp.Wallet_Type = tran.walletType ? tran.walletType : "";
    //                     temp.Corporate_Name = tran.corporateName ? tran.corporateName : ""
    //                     temp.SP_ID = tran.spID;
    //                     temp.Transaction_ID = tran.transactionID;
    //                     temp.Acknowledge_Status = tran.acknowledgeStatus ? "Success" : "Failure"
    //                     if (tran.status) {
    //                         temp.status = "Success";
    //                     } else {
    //                         temp.status = "Failure";
    //                     }

    //                     temp.Payment_Mode = tran.paymentMode;
    //                     temp.Total_Amount = tran.totalAmount;
    //                     temp.Category = tran.category;
    //                     temp.Biller_Type = tran.biller_type;
    //                     return temp;
    //                 });
    //                 setCsvData(updatedCsvData);
    //             } else {
    //                 setTransactions([]);
    //                 setShowLoader(false);
    //                 setShowTransactionTable(true);
    //                 openSnackBar(resData.msg);
    //             }
    //         })
    //             .catch((err) => {
    //                 setSubmitting(false)
    //                 setTransactions([]);
    //                 setShowLoader(false);
    //                 setShowTransactionTable(true);
    //                 openSnackBar("Unable to process");

    //             });
    //     }
    // }


    const resetSearch = () => {
        //function to reset search
        setTxnType("ALL")
        setFromDate(moment(new Date).format("YYYY-MM-DD"))
        setToDate(moment(new Date).format("YYYY-MM-DD"))
        setTimeout(function () {
            searchTransactions()
        }, 2000);

    }


    const columns = [
        {
            field: "transactionDate",
            title: "Date",
            render: rowData => moment.utc(rowData.transactionDate).local().format("DD/MM/YYYY h:mm:ss A"),
        },
        {
            field: "userDetails.cpr",
            title: "CPR",
        },
        {
            field: "walletType",
            title: "Wallet Type",
            render: rowData => {
                return rowData?.sender.accountCategory ? rowData.sender?.accountCategory : 'N/A';
            }
        },
        
        {
            field: "transactionId",
            title: "Transaction ID",
        },
        // {
        //     field: "walletType",
        //     title: "Wallet Type",
        // },
        {
            field: "transactionType",
            title: "Transaction Type",
        },
        {
            field: "sender.walletAccId",
            title: "Sender Account Number",
        },

        {
            field: "receiver",
            title: "Receiver Cpr/Mobile/Account Number/IBAN",
            render: rowData => {
                const receiver = rowData.receiver;
                const fields = [receiver.entityId, receiver.mobile, receiver.walletAccId, receiver.iban];
                return fields.find(field => field) || 'N/A';
            }
        },
        // {
        //     field: "category",
        //     title: "Category",
        // },
        // {
        //     field: "biller_type",
        //     title: "Biller Type",
        // },
        {
            field: "status",
            title: "Status",
            render: rowData => {
                let chipColor = "";
                let chipLabel = rowData.status;

                // Set background color and label text for each status
                switch (rowData.status.toUpperCase()) {
                    case "COMPLETED":
                        chipColor = "green";
                        chipLabel = "Completed";
                        break;
                    case "FAILED":
                        chipColor = "red";
                        chipLabel = "Failed";
                        break;
                    case "INITIATED":
                        chipColor = "gray";
                        chipLabel = "Initiated";
                        break;
                    case "PENDING":
                        chipColor = "#FFC107"; // Amber for pending
                        chipLabel = "Pending";
                        break;
                    default:
                        chipColor = "blue"; // Default color if status is unknown
                        chipLabel = rowData.status;
                        break;
                }

                return (
                    <Chip
                        size="small"
                        style={{ backgroundColor: chipColor, color: "white", textAlign: "center", width: "90px" }}
                        label={chipLabel}
                    />
                );
            }
        },
        // {
        //     field: "ackStatusUpdated",
        //     title: "Acknowledgment Status",
        // },
        {
            field: "netAmount",
            title: "Total Amount",
            render: rowData => rowData.netAmount ? parseFloat(rowData.netAmount).toFixed(3) : "0.000"
        },

        {
            field: "reason",
            title: "Reason",
        },
    ];

    const options = {
        showTitle: false,
        actionsColumnIndex: -1,
        exportButton: { csv: true },
        exportCsv: () => {
            let csvFile = creatCsvFile(csvData);
            let fileName = `${searchedKey}_${moment(fromDate).format("MM-DD-YYYY")}_to_${moment(toDate).format("MM-DD-YYYY")}.csv`;
            downloadFile(csvFile, fileName);
            return false;
        },
        pageSize: rowsPerPage, // Default rows per page
        page: page, // Current page index
        showFirstLastPageButtons: true, // Show first/last page buttons
        onPageChange: (newPage) => {
            setPage(newPage); // Update the page state
            searchTransactions(newPage + 1, rowsPerPage); // Fetch new data based on the updated page
        },
        onRowsPerPageChange: (newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage); // Update the number of rows per page
            setPage(0); // Reset to the first page
            searchTransactions(1, newRowsPerPage); // Fetch data for the first page with new rows per page
        },
        count: 30, // Use 'count' instead of 'totalCount'
    };

    const Actions = [
        // {
        //     icon: "list_lt_icon",
        //     tooltip: 'Transaction Details',
        //     onClick: (event, rowData) => loadTransactionDetails(rowData.transactionID),
        // },



    ]


    const loadTransactionDetails = (id) => {
        //function to load transaction details
        setTransactionDetails(<Details transactionID={id.slice(7)} type="avenues" />)
        setShowDetails(true)
    }

    const searchTransactions = async () => {
        try {
            setTransactions([])
                setSuccessTransactions([])
                setFailedTransactions([])
                setInitatedTransaction([])
            let failedData = [], successData = [], initiatedData = [], pending = [];
            let data = {
                startDate: fromDate,
                endDate: toDate,
                txnType: txnType=='ALL'?'':txnType,
                page: null,
                limit: null
            };
            setShowLoader(true)
            setShowTransactionTable(false);

            const resp = await axios.post(`${config.url().baseURL}/getIban/txn/list`, data);

            if (resp?.data?.data?.[0]?.transactions?.length) {
                const transactions = resp.data.data[0].transactions;
                setTransactions(transactions);
                transactions.forEach(tran => {
                    if (tran.status.toUpperCase() === "COMPLETED") {
                        successData.push(tran);
                    } else if (tran.status.toUpperCase() === "FAILED") {
                        failedData.push(tran);
                    } else if (tran.status.toUpperCase() === "INITIATED") {
                        initiatedData.push(tran);
                    } else if (tran.status.toUpperCase() === "PENDING") {
                        pending.push(tran);
                    }
                });


                var updatedCsvData = transactions.map((tran) => {
                    let temp = {};
                    temp.Date = tran.transactionDate;
                    temp.CPR = tran.userDetails.cpr;
                    temp.Sender_Wallet_Type = tran.sender.accountCategory
                    temp.transactionID = tran.transactionId;
                    temp.transactionType = tran.transactionType
                    temp.Sender_Wallet_Account_Number = tran.sender.walletAccId;
                    temp.ReceiverIban_Or_Account_Number = tran.receiver.iban ? tran.receiver.iban : tran.receiver.walletAccId;
                    temp.status = tran.status
                    temp.Total_Amount = tran.netAmount;
                    temp.failedReason = tran.reason;


                    return temp;
                });
                setCsvData(updatedCsvData);



                setSuccessTransactions(successData)
                setPendingTransaction(pending)
                setFailedTransactions(failedData)
                setInitatedTransaction(initiatedData)

            } else {
                console.error("No transactions found in response");
                
                openSnackBar("No transactions found.");
            }

            // Log success transactions for debugging

            // Set UI state after processing
            setShowLoader(false);
            setShowTransactionTable(true);

        } catch (error) {
            // Handle errors gracefully and provide user feedback
            console.error("Error fetching data:", error);
            openSnackBar("Failed to load transactions.");
            setShowLoader(false);
        }
    };




    function a11yProps(index) {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);

    };


    return (
        <div>
            {respAlert}
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showLoader}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showTransactionTable}
                        style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                        {...(true ? { timeout: 500 } : {})}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>From Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setFromDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setFromDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setFromDate(event.target.value)
                                                }
                                            },
                                            value: fromDate,
                                        }}
                                    />
                                </Paper>

                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>To Date</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        autoFocus={true}
                                        fullWidth
                                        className={classes.input}
                                        inputProps={{
                                            type: "date",
                                            max: moment(new Date()).format('YYYY-MM-DD'),
                                            onChange: (event) => { setToDate(event.target.value) },
                                            onBlur: (event) => {
                                                if (moment(event.target.value).isAfter(moment(new Date()))) {
                                                    setToDate("")
                                                    openSnackBar("Please enter valid date.")
                                                } else {
                                                    setToDate(event.target.value)
                                                }
                                            },
                                            value: toDate,
                                        }}
                                    />
                                </Paper>
                                <div style={{ textAlign: "center" }}>
                                    <Tooltip placement="bottom" title="Reset">
                                        <IconButton className={classes.addDeptButton}
                                            onClick={() => resetSearch()}
                                            size="lg" color="primary">
                                            <RotateLeftIcon fontSize="medium" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <p className={classes.searchHead}>Type</p>
                                <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                                    <FormControl className={classes.groupSelect} fullWidth>
                                        <Select
                                            id="group-select"
                                            value={txnType}
                                            disableUnderline
                                            onChange={(e) => {
                                                setTxnType(e.target.value);
                                                // searchTransactions();
                                            }}>
                                            <MenuItem value="ALL">ALL</MenuItem>
                                            <MenuItem value="Internal-Transfer">P2P</MenuItem>
                                            <MenuItem value="Internal-AUB-Fawri">AUB </MenuItem>
                                            <MenuItem value="External-Transfer-Fawri">External Fawri </MenuItem>
                                            <MenuItem value="External-Transfer-FawriPlus">External Fawri Plus</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {!submitting ? (
                                        <Button className={classes.addDeptButton}
                                            onClick={() => searchTransactions()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={35} />
                                    )}
                                </Paper>

                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <Card>
                                    <CardHeader color="primary">
                                        <AppBar position="static" style={{ background: 'transparent', boxShadow: 'none', color: "white" }}>
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                // indicatorColor="secondary"
                                                textColor="white"
                                                variant="fullWidth"
                                                classes={{
                                                    indicator: classes.indicator
                                                }}>
                                                <Tab label={`All(${transactions.length})`} {...a11yProps(0)} />
                                                <Tab label={`Success(${successTransactions.length})`} {...a11yProps(0)} />
                                                <Tab label={`Failed(${failedTransactions.length})`} {...a11yProps(0)} />
                                                <Tab label={`Pending(${pendingTransaction.length})`} {...a11yProps(0)} />
                                                <Tab label={`Initiated(${InitiatedTransaction.length})`} {...a11yProps(0)} />

                                            </Tabs>
                                        </AppBar>
                                    </CardHeader>
                                    <CardBody>
                                        <SwipeableViews
                                            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                                            index={value}
                                            className={classes.tabContent}
                                            onChangeIndex={handleChangeIndex}>
                                            <TabPanel value={value} index={0} dir={theme.direction}>
                                                <MaterialTable
                                                    actionsvalidateSearch={Actions}
                                                    components={{
                                                        Container: (props) => <Paper {...props} elevation={0} />,
                                                    }}
                                                    data={transactions} columns={columns}
                                                    options={options} />
                                            </TabPanel>
                                            <TabPanel value={value} index={1} dir={theme.direction}>
                                                <MaterialTable
                                                    actions={Actions}
                                                    components={{
                                                        Container: (props) => <Paper {...props} elevation={0} />,
                                                    }}
                                                    data={successTransactions} columns={columns} options={options} />
                                            </TabPanel>
                                            <TabPanel value={value} index={2} dir={theme.direction}>
                                                <MaterialTable
                                                    actions={Actions}
                                                    components={{
                                                        Container: (props) => <Paper {...props} elevation={0} />,
                                                    }}
                                                    data={failedTransactions} columns={columns} options={options} />
                                            </TabPanel>
                                            <TabPanel value={value} index={3} dir={theme.direction}>
                                                <MaterialTable
                                                    actions={Actions}
                                                    components={{
                                                        Container: (props) => <Paper {...props} elevation={0} />,
                                                    }}
                                                    data={pendingTransaction} columns={columns} options={options} />
                                            </TabPanel>

                                            <TabPanel value={value} index={4} dir={theme.direction}>
                                                <MaterialTable
                                                    actions={Actions}
                                                    components={{
                                                        Container: (props) => <Paper {...props} elevation={0} />,
                                                    }}
                                                    data={InitiatedTransaction} columns={columns} options={options} />
                                            </TabPanel>
                                        </SwipeableViews>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </Collapse>
                </GridItem>
            </GridContainer>
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.companyLogModal,
                }}
                open={showDetails}
                onClose={() => {
                    setShowDetails(false)
                    setTransactionDetails(null)
                }}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {transactionDetails}
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => { setShowDetails(false); setTransactionDetails(null) }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setTransactionDetails(null)
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </div>
    );
}