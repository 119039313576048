import React, { useRef } from 'react';
import axios from "axios";
import JSONPretty from 'react-json-pretty';
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import PropagateLoader from "react-spinners/PropagateLoader";
import PulseLoader from "react-spinners/PulseLoader";
import { css } from "@emotion/core";
import { creatCsvFile, downloadFile } from "download-csv";
import Magnifier from "react-magnifier";
import { useSelector } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useDispatch } from "react-redux";
import config from "config.js"
import { setCompanyInfo, setOwnersAndCtrls, setBusinessProfile, setCompanyID } from "redux/actions/corporateOnboarding.js";
import countries from "variables/countries.json";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Collapse from "@material-ui/core/Collapse";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Skeleton from '@material-ui/lab/Skeleton';
import SubjectIcon from '@material-ui/icons/Subject';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import Chip from "@material-ui/core/Chip";
import Switch from '@material-ui/core/Switch';
import PageviewIcon from '@material-ui/icons/Pageview';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import { primaryColor } from "assets/jss/material-dashboard-react.js";
import ImagesStyles from "assets/jss/material-dashboard-react/imagesStyles.js";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import ReplayIcon from '@material-ui/icons/Replay';

//steps
import CompanyInformation from "./Steps/CompanyInformation";
import OwnersAndControllers from "./Steps/OwnersAndControllers";
import KYCDocuments from "./Steps/KYC_Documents";

import DeleteIcon from "@material-ui/icons/Delete"
import NameScreen from "./Components/NameScreen.js"
import NameScreenWithoutApprove from "./Components/NameScreenWithoutApprove.js"
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const override = css`
  padding: 0;
  margin: 0;
`;
const useStyles = makeStyles((theme) => ({
  ...SweetAlertStyle,
  ...ImagesStyles,
  root: {
    width: "100%",
    // textAlign: "center",
  },
  mainHeadArea: {
    width: "100%",
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    float: "left",
    display: "inlineBlock",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  addButton: {
    float: "right !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  backContainer: {
    width: "100%",
    textAlign: "center",
  },
  logContainer: {
    maxHeight: "500px",
    overflowY: "scroll",
  },
  corpTopHead: {
    fontSize: "15px",
    color: primaryColor[0],
    fontWeight: "400",
  },
  thumbnailContainer: {
    overflowX: "scroll",
    width: "100%",
    whiteSpace: "nowrap",
    '&::-webkit-scrollbar-track': {
      height: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar': {
      height: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: " 6px",
      backgroundColor: primaryColor[0]
    }
  },
  thumbnail: {
    marginLeft: "4px",
    marginRight: "4px",
    width: "100px !important",
    height: "100px !important",
    display: "inlineBlock",
    whiteSpace: "normal"
  },
  detailsContainer: {
    padding: "0px",
    paddingTop: "-20px",
    marinTop: "-10px",
    maxHeight: '75vh',
    overflow: 'auto',
    '&::-webkit-scrollbar-track': {
      width: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar': {
      width: "8px",
      backgroundColor: "#F5F5F5"
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: " 6px",
      backgroundColor: primaryColor[0]
    }
  },
  imageName: {
    fontSize: "15px",
    color: primaryColor[0],
    fontWeight: "500",
    marginTop: "0px",
    marginBottom: "10px"
  },
  topBackButton: {
    float: "right"
  },
  corpDetailsTableHead: {
    marginLeft: "10px",
    marginTop: "20px",
    marginBottom: "-30px",
    fontSize: "13px",
    fontWeight: "500",
    textTransform: "none"
  },
  ...modalStyle(theme),
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function HorizontalLabelPositionBelowStepper() {
  const companyID = useSelector((state) => state.corporateOnboarding.companyID);
  const classes = useStyles();
  const scrollRef = useRef(null);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [csvData, setCsvData] = React.useState([]);
  const [corporateData, setCorporateData] = React.useState([]);
  const [logData, setLogData] = React.useState([]);
  const [onBoardingLogs, setOnBoardingLogs] = React.useState([]);
  const [corpDetails, setCorpDetails] = React.useState([]);
  const [corpDocs, setCorpDocs] = React.useState([]);
  const [companyName, setCompanyName] = React.useState([]);
  const [companyInfo, setCompanyInformation] = React.useState([]);
  const [contactInfo, setContactInfo] = React.useState([]);
  const [ownerInfo, setOwnerInfo] = React.useState([]);
  const [porInfo, setPorInfo] = React.useState([]);
  const [pepInfo, setPepInfo] = React.useState([]);
  const [dirInfo, setDirInfo] = React.useState([]);
  const [businessInfo, setBusinessInfo] = React.useState([]);
  const [declarationInfo, setDeclarationInfo] = React.useState([]);
  const [lorInfo, setLorInfo] = React.useState([]);
  const [bankInfo, setBankInfo] = React.useState([]);
  const [feeInfo, setFeeInfo] = React.useState([]);
  const [magnifierImage, setMagnifierImage] = React.useState(null);
  const [docName, setDocName] = React.useState("");
  const [remarks, setRemarks] = React.useState(false);
  const [erpCode, setErpCode] = React.useState("");
  const [action, setAction] = React.useState("");
  const [corpID, setCropID] = React.useState(null);

  const [showLoader, setShowLoader] = React.useState(true);
  const [showAddCorporate, setShowAddCorporate] = React.useState(false);
  const [showCorporateTable, setShowCorporateTable] = React.useState(false);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [logModal, setLogModal] = React.useState(false);
  const [onBoardingLogModal, setOnBoardingLogModal] = React.useState(false);
  const [showCorpDetails, setShowCorpDetails] = React.useState(false);
  const [openRemarksModal, setOpenRemarksModal] = React.useState(false);
  const [remarksErr, setRemarksErr] = React.useState(false);
  const [erpCodeErr, setErpCodeErr] = React.useState(false);
  const [showAddCorpBtn, setShowAddCorpBtn] = React.useState(false);
  const [showActionBtn, setShowActionBtn] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [respAlert, setRespAlert] = React.useState(null);
  const [recheckBtn, setRecheckBtn] = React.useState(null);


  const [showErp, setShowErp] = React.useState(false);
  const [riskStatus, setRiskStatus] = React.useState(false);
  const [userType, setUserType] = React.useState("");
  const [riskUserType, setRiskUserType] = React.useState("");
  const [showImage, setShowImage] = React.useState(false);
  const [numPages, setNumPages] = React.useState(null);
  const [pageNumber, setPageNumber] = React.useState(1);

  const [normalBtn, setNormalBtn] = React.useState(null);
  const [exceptionBtn, setExceptionBtn] = React.useState(null);
  const [rejectBtn, setRejectBtn] = React.useState(null);
  const [lorData, setLorData] = React.useState([]);
  const [lorEnabled, setLorEnabled] = React.useState({});
  const [showEnableLogin, setShowEnableLogin] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [magnifierZoom, setMagnifierZoom] = React.useState(1);
  const [pdfZoom, setPdfZoom] = React.useState(1);

  const [comInfoKey, setComInfoKey] = React.useState(1);
  const [comID, setComID] = React.useState("");
  const [editProps, setEditProps] = React.useState(false);
  const [cacheEditProps, setCacheProps] = React.useState(false);
  const [backProps, setBackProps] = React.useState(false);
  const [showNameScreen, setShowNameScreen] = React.useState(false);
  const [showNameScreenWithoutApproval, setShowNameScreenWithoutApproval] = React.useState(false);
  const [showNameScreenBtn, setShowNameScreenBtn] = React.useState(null);

  const [nameScreen, setNameScreen] = React.useState(null);
  const [nameScreeningStatus, setNameScreeningStatus] = React.useState(false);


  const steps = getSteps();

  const openSnackBar = (msg) => {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleZoomIn = () => {
    console.log("handleZoomIn")
    setPdfZoom((prevZoom) => prevZoom + 0.5);
  };

  const handleZoomOut = () => {
    console.log("handleZoomOut")
    setPdfZoom((prevZoom) => prevZoom - 0.5);
  };


  const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status Info', minWidth: 170 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },


  ];

  const columns = [
    {
      name: "no",
      label: "#",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "date",
      label: " Created Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "companyID",
      label: "Company ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "CRExpiryDate",
      label: "CR Expiry Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nextLevel",
      label: "Next Level",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "kycStatus",
      label: "KYC Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "exception",
      label: "Exception",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "kycAppID",
      label: "KYC Application ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ledgerID",
      label: "Ledger Customer ID",

      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "accountNo",
      label: "Account NO",

      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "m2pWallet",
      label: "Corprate Wallet Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && value.status ? value.status : "N/A";
        },
      },
    },
    // {
    //   name: "enableLOR",
    //   label: "Enable Legal Rep",

    //   options: {
    //     filter: false,
    //     sort: false,
    //     setCellProps: () => ({ style: { marginTop: "-10", marginBottom: "-10" } }),
    //     customBodyRender: (data, type, row) => { return <pre>{data}</pre> }
    //   },
    // },
    {
      name: "actions",
      label: "Actions",

      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({ style: { minWidth: "100px", textAlign: "left", marginTop: "-10", marginBottom: "-10" } }),
        customBodyRender: (data, type, row) => { return <pre>{data}</pre> }

      },
    },
    
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    print: false,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "corporate.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  React.useEffect(() => {
    // if (companyID === undefined || companyID === null) {
    //   setActiveStep(0);
    // }
    checkUserType()
    fetchCorporateData();
  }, []);

  React.useEffect(() => {
    checkUserType()
  }, [user]);

  React.useEffect(() => {
    scrollTop()
  }, [activeStep])

  React.useEffect(() => {
    dispatch(setCompanyInfo([]));
    dispatch(setOwnersAndCtrls([]));
    dispatch(setBusinessProfile([]));
  }, [])

  const checkUserType = async () => {
    //function to check what type of user is accessing the page
    if (user.admin !== undefined && user.admin == true) {
      setShowActionBtn(true)
    } else {
      if (user.services !== undefined && user.services !== null && user.services.length !== 0) {
        let service = user.services.find(service => service.name == "Corporate Onboarding")
        if (service) {
          setUserType(service.type)
          switch (service.type) {
            case "maker":
              setShowAddCorpBtn(true)
              break;
            case "checker":
              setShowAddCorpBtn(false)
              setShowActionBtn(true)
              break;
            case "checkerTwo":
              setShowAddCorpBtn(false)
              setShowActionBtn(true)
              break;
            case "approver":
              setShowAddCorpBtn(false)
              setShowActionBtn(true)
              break;
            case "viewer":
              setShowAddCorpBtn(false)
              break;

            default:
              setShowAddCorpBtn(false)
              break;
          }
        }
      }
      if (user.riskServices !== undefined && user.riskServices !== null && user.riskServices.length !== 0) {
        let riskService = user.riskServices.find(riskService => riskService.name == "Corporate Onboarding")
        if (riskService) {
          setRiskUserType(riskService.type)
        }
      }
    }

  }
  // const openRemarks = (action) => {
  //   console.log('fdgjgd')
  //   //open remarks model
  //   setRemarks("")
  //   setAction(action)

  //   // setOpenRemarksModal(true
  //   if (riskStatus) {
  //     if (riskUserType == "checkerThree" && action == "approve") {

  //       erpfetch()
  //       setShowErp(true)

  //     } else {
  //       setShowErp(false)
  //     }
  //     if (riskUserType == "checker" && action == "approve") {
  //       setShowEnableLogin(true)
  //     } else {
  //       setShowEnableLogin(false)
  //     }
  //   } else {
  //     console.log('fdgjgd')
  //     if (userType == "checkerTwo" && action == "approve") {
  //       setShowErp(true);
  //       // setOpenRemarksModal(true);
  //       erpfetch();

  //     } else {
  //       // setOpenRemarksModal(false);
  //       setShowErp(false);
  //     }
  //     if (userType == "checker" && action == "approve") {
  //       setShowEnableLogin(true)
  //       setOpenRemarksModal(true)
  //     } else {
  //       setShowEnableLogin(false)
  //       setOpenRemarksModal(false)
  //     }
  //   }
  // }
  const openRemarks = (action) => {

    let data = {
      companyid: corpID,
    }
    // openRemarksModal()
    setRemarks("")
    setAction(action)
    setOpenRemarksModal(true)
    if (riskStatus) {
      if (riskUserType == "checkerThree" && action == "approve") {
        axios.post(`${config.url().baseURL}/create-erp-account`, data).then((res) => {
          // console.log (respData)    
          let respData = res.data
          if (!respData.err) {
            console.log(res.data)
            setErpCode(respData.data.erpCode)
            setOpenRemarksModal(true)
            setShowErp(true)
          }
          else {
            openSnackBar(respData.data.msg)
            setOpenRemarksModal(false)
            setShowErp(false)
          }
        }).catch((err) => {
          // console.log(err)
          // openSnackBar("Failed to generate ERP Code,Please Try again later")
          setOpenRemarksModal(false)
          setShowErp(false)
        })

      } else {
        setOpenRemarksModal(true)
        setShowErp(false)
      }
      if (riskUserType == "checker" && action == "approve") {
        setShowEnableLogin(true)
        // setOpenRemarksModal(true)
      } else {
        setShowEnableLogin(false)
        // setOpenRemarksModal(false)
      }
    } else {
      if (userType == "checkerTwo" && action == "approve") {
        axios.post(`${config.url().baseURL}/create-erp-account`, data).then((res) => {
          let respData = res.data
          if (!respData.err) {
            setErpCode(respData.data.erpCode)
            setOpenRemarksModal(true)
            setShowErp(true)
          }
          else {
            openSnackBar(respData.data.msg)
            setOpenRemarksModal(false)
            setShowErp(false)
          }
        }).catch((err) => {
          console.log(err)
          openSnackBar("Failed to generate ERP Code,Please Try again later")
          setOpenRemarksModal(false)
          setShowErp(false)
        })
        setShowErp(true)
        setOpenRemarksModal(true)
      } else {
        setShowErp(false)
        setOpenRemarksModal(true)
      }
      if (userType == "checker" && action == "approve") {
        setShowEnableLogin(true)
        setOpenRemarksModal(true)
      } else {
        setOpenRemarksModal(true)
        setShowEnableLogin(false)
      }
    }
  }



  const validateRemarks = () => {
    //function to validate remarks dialogue
    let flag = true
    if (remarks.length < 5) {

      setRemarksErr(true)
      openSnackBar("Remarks must be five letter long.")
      flag = false
    }
    if (riskStatus) {
      if (userType == "checkerThree" && action == "approve") {
        if (erpCode.length == 0) {
          setErpCodeErr(true)
          openSnackBar("Please enter ERP code.")
          flag = false
        }
      }
    } else {
      if (userType == "checkerTwo" && action == "approve") {
        if (erpCode.length == 0) {
          setErpCodeErr(true)
          openSnackBar("Please enter ERP code.")
          flag = false
        }
      }
    }

    return flag
  }

  const changeStatus = () => {
    //function to change status of company
    if (validateRemarks()) {
      setSubmitting(true)
      let data = {}
      if (riskStatus) {
        data = {
          user: user._id,
          authority: riskUserType,
          remarks: remarks,
          action: action,
          erp: erpCode,
          riskStatus: riskStatus,
          lorStatus: lorEnabled
        }
      } else {
        data = {
          user: user._id,
          authority: userType,
          remarks: remarks,
          action: action,
          erp: erpCode,
          riskStatus: riskStatus,
          lorStatus: lorEnabled
        }
      }
      axios.post(`${config.url().baseURL}/update-company-status/${corpID}`, data,
        {
          headers: {
            auth: sessionStorage.getItem("cx_id"),
          },
        }
      ).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setOpenRemarksModal(false)
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Company status updated."
              onConfirm={() => {
                setRemarks("");
                setErpCode("")
                setRespAlert(null);
                handleBackClick();
                setSubmitting(false)
              }
              }
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );
        } else {
          openSnackBar(respData.msg);
          setOpenRemarksModal(false)
          setSubmitting(false);
        }
      })
        .catch((err) => {
          openSnackBar("Unable to process.");
        });
    }
  }
  const confirmDeleteCorporate = (id) => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          deleteCorporate(id)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        Do you really want to delete the corporate?
      </SweetAlert>
    )
  }
  const deleteCorporate = (id) => {
    axios.delete(`${config.url().baseURL}/delete-corporate/${id}`)
      .then((resp) => {
        let respData = resp.data
        if (!respData.err) {
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title="Corporate delete successful."
              onConfirm={() => {
                setRespAlert(null);
                fetchCorporateData();
              }}
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );
        } else {
          openSnackBar(respData.msg)
        }
      })
  }

  const fetchCorporateData = () => {
    setShowCorporateTable(false)
    //function to fetch corporate data
    dispatch(setCompanyInfo([]));
    dispatch(setOwnersAndCtrls([]));
    dispatch(setBusinessProfile([]));
    let service = user.services.find(service => service.name == "Corporate Onboarding")
    let riskService = user.riskServices.find(service => service.name == "Corporate Onboarding")
    axios.get(`${config.url().baseURL}/get-company`, {
      headers: {
        auth: sessionStorage.getItem("cx_id"),
      },
    })
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          var updatedData = []
          respData.data.map((corp, key) => {
            try {
              corp.no = key + 1;
              corp.date = moment(corp.date).format("DD/MM/YYYY")
              corp.companyID = corp.incorp_details.comm_reg_id
              corp.CRExpiryDate = moment(corp.incorp_details.exp_date).format("DD/MM/YYYY")
              if (corp.status == "Step One Completed") {
                corp.status = "First Level Form Completed"
                corp.nextLevel = "Maker"
              } else if (corp.status == "Step Two completed") {
                corp.status = "Second Level Form Completed"
                corp.nextLevel = "Maker"
              } else if ((corp.status == "Created") || (corp.status == "Updated")) {
                corp.nextLevel = "Operation Checker"
              } else if (corp.status == "Recheck") {
                corp.nextLevel = "Maker"
              } else if (corp.status == "Rejected") {
                corp.nextLevel = "Rejected"
              } else if (corp.status == "Checked" && corp.risk_status == true) {
                corp.nextLevel = "Compliance"
              } else if (corp.status == "Checked" && corp.risk_status == false) {
                corp.nextLevel = "Finance Approver"
              } else if (corp.status == "Level Two Checked" && corp.risk_status == true) {
                corp.nextLevel = "Finance Approver"
              } else if (corp.status == "Level Two Checked" && corp.risk_status == false) {
                corp.nextLevel = "Sales Head"
              } else if (corp.status == "Level Three Checked") {
                corp.nextLevel = "Sales Head";
              } else if (corp.status == "Approved") {
                corp.nextLevel = "Onboarded"
              } else if (corp.status == undefined) {
                corp.nextLevel = "Maker"
              }
              else {
                corp.status = corp.status;
              }

              corp.kycStatus = corp.KYC.status;
              corp.kycAppID = corp.KYC.ApplicationID;
              corp.ledgerID = corp.Ledger.customerId;
              corp.accountNo = corp.Ledger.AccountNumber;
              corp.exception = corp.risk_status
              corp.approvedDate = corp.Ledger && corp.Ledger.LedgerAddedOn ? moment(corp.Ledger.LedgerAddedOn).format("DD/MM/YYYY") : "";
              if (!corp.risk_status) {
                if (corp.enableEdit && service !== undefined && service.type == "maker") {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => fetchLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="View Details">
                        <IconButton
                          onClick={() => fetchCompanyDetails(corp._id, corp.status, corp.risk_status)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Edit">
                        <IconButton
                          onClick={() => editCompanyData(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {(service.type != "viewer") && corp.enableRetry ? (
                        <Tooltip placement="top" title="Retry Corporate">
                          <IconButton
                            onClick={() => confirmRetryCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="log">
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      {(service.type != "viewer") && (corp.kycAppID == "") ? (
                        <Tooltip placement="right" title="Delete Corporate">
                          <IconButton
                            onClick={() => confirmDeleteCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>) : null
                      }
                    </>
                  );
                } else if (corp.enableCompleteDetails && service !== undefined && service.type == "maker") {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => fetchLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip> {(corp.kycAppID == "") ?
                        <Tooltip placement="right" title="Complete Onboarding">
                          <IconButton
                            onClick={() => {
                              completeCorporate(corp._id, corp.status);
                              dispatch(setCompanyID(corp._id))
                            }}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="log">
                            <ExitToAppIcon />
                          </IconButton>
                        </Tooltip> : <Tooltip placement="right" title="View Details">
                          <IconButton
                            onClick={() => fetchCompanyDetails(corp._id, corp.status, corp.risk_status)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="log">
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>}
                      {(service.type != "viewer") && (corp.kycAppID == "") ? (
                        <Tooltip placement="right" title="Delete Corporate">
                          <IconButton
                            onClick={() => confirmDeleteCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>) : null
                      }
                    </>
                  );
                }
                else {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => {
                            if (corp.status_message == "Amtal Migrated Corporate") {
                              fetchLog(corp.incorp_details.comm_reg_id)
                            } else {
                              fetchLog(corp._id)
                            }
                          }
                          }
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="View Details">
                        <IconButton
                          onClick={() => fetchCompanyDetails(corp._id, corp.status, corp.risk_status)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {(service.type != "viewer") && corp.enableRetry ? (
                        <Tooltip placement="top" title="Retry Corporate">
                          <IconButton
                            onClick={() => confirmRetryCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="log">
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </>
                  );
                }
              } else {
                if (corp.enableEdit && riskService !== undefined && riskService.type == "maker") {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => fetchLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="View Details">
                        <IconButton
                          onClick={() => fetchCompanyDetails(corp._id, corp.status, corp.risk_status)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Edit">
                        <IconButton
                          onClick={() => editCompanyData(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      {(service.type != "viewer") && corp.enableRetry ? (
                        <Tooltip placement="top" title="Retry Corporate">
                          <IconButton
                            onClick={() => confirmRetryCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="log">
                            <ReplayIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                      {(service.type != "viewer") && (corp.kycAppID == "") ? (
                        <Tooltip placement="right" title="Delete Corporate">
                          <IconButton
                            onClick={() => confirmDeleteCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>) : null
                      }
                    </>
                  );
                } else if (corp.enableCompleteDetails && service !== undefined && service.type == "maker") {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => fetchLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Complete Onboarding">
                        <IconButton
                          onClick={() => completeCorporate(corp._id, corp.status)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <ExitToAppIcon />
                        </IconButton>
                      </Tooltip>
                      {(service.type != "viewer") && (corp.kycAppID == "") ? (
                        <Tooltip placement="right" title="Delete Corporate">
                          <IconButton
                            onClick={() => confirmDeleteCorporate(corp._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px", color: "#f05945" }}
                            aria-label="delete">
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>) : null
                      }
                    </>
                  );
                } else {
                  corp.actions = (
                    <>
                      <Tooltip placement="right" title="View Log">
                        <IconButton
                          onClick={() => fetchLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <AssignmentIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="Corporate Status Log">
                        <IconButton
                          onClick={() => fetchOnboardingLog(corp._id)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <SubjectIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="right" title="View Details">
                        <IconButton
                          onClick={() => fetchCompanyDetails(corp._id, corp.status, corp.risk_status)}
                          style={{ marginTop: "-10px", marginBottom: "-10px" }}
                          aria-label="log">
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>

                    </>
                  );
                }
              }

              if (corp.KYC.status == "APPROVED") {
                if (corp.lor_enabled) {
                  corp.enableLOR = (<Switch
                    checked={corp.lor_enabled}
                    onChange={() => changeLorStatus(corp._id, corp.lor_enabled)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />)
                } else {
                  corp.enableLOR = (<Switch
                    checked={false}
                    onChange={() => changeLorStatus(corp._id, false)}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />)
                }

              } else {
                corp.enableLOR = (<Switch disabled inputProps={{ 'aria-label': 'primary checkbox' }} />)

              }
              if (corp.risk_status) {
                corp.exception = "True"
              } else {
                corp.exception = "False"
              }
              updatedData.push(corp);
            } catch (error) {

            }

          });
          setCorporateData(updatedData);
          setShowLoader(false);
          setShowCorporateTable(true);
          var updatedCsvData = respData.data.map((corp) => {
            let temp = {};
            temp.Sl_No = corp.no;
            temp.Created_Date = corp.date;
            temp.Company_ID = corp.incorp_details.comm_reg_id;
            temp.CR_Expiry_Date = moment(corp.incorp_details.exp_date).format("DD/MM/YYYY");
            temp.Company_Name = corp.companyName.replaceAll(/,/g, '');
            temp.Status = corp.status;
            temp.Next_Level = corp.nextLevel;
            temp.KYC_Status = corp.kycStatus;
            temp.KYC_ApplicationID = corp.kycAppID;
            temp.LedgerID = corp.ledgerID;
            temp.AccountNo = corp.accountNo;
            temp.Exception = corp.exception;
            temp.Approved_Date = corp.approvedDate;
            temp.Corp_Wallet_Status = corp.m2pWallet?.status?corp.m2pWallet.status:"N/A"
            return temp;
          });
          setCsvData(updatedCsvData);
        } else {
          setCorporateData([]);
          setShowLoader(false);
          setShowCorporateTable(true);
          openSnackBar(respData.msg);
        }
      })
      .catch((err) => {
        openSnackBar("Unable to fetch Corporate data.");
      });
  };

  const completeCorporate = (id, status) => {
    //function to complete corporate
    if (status == "Step One Completed" || status == "First Level Form Completed") {
      dispatch(setCompanyID(id));
      setActiveStep(1)
      handleAddClick()
    }
    else {
      setActiveStep(2)
      dispatch(setCompanyID(id));
      handleAddClick()
    }
  }

  const fetchLog = (id) => {
    //function to fetch company logs
    axios.get(`${config.url().baseURL}/get-company-log/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setLogModal(true);
        setLogData(respData.data);
      } else {
        setLogModal(false);
        openSnackBar(respData.msg);
      }
    });
  };

  const fetchCorpDoc = (id, company) => {
    //function to fetch company logs
    setMagnifierImage(null)
    setPageNumber(1)
    axios.post(`${config.url().baseURL}/get-company-doc/${id}`, { company: company }).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        if (respData.doc) {
          let extension = respData.doc.substring(respData.doc.indexOf("/") + 1, respData.doc.indexOf(";base64"));
          changeMagnifierImage(respData.doc, respData.docType)
          if (extension == "pdf") {
            setShowImage(false)
          } else {
            setShowImage(true)
          }
        } else {
          openSnackBar("Invalid document.");
        }


      } else {
        setMagnifierImage(null)
        openSnackBar(respData.msg);
      }
    });
  };

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  const fetchOnboardingLog = (id) => {
    //function to fetch company logs
    setOnBoardingLogModal(true);
    axios.get(`${config.url().baseURL}/get-company-onboarding-logs/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        //console.log(JSON.stringify(respData.data[0].log))
        if (respData.data[0] !== undefined) {
          if (respData.data[0].log !== undefined) {
            setOnBoardingLogs(respData.data[0].log);
          } else {
            setOnBoardingLogModal(false);
            openSnackBar("No log found.");
          }
        } else {
          setOnBoardingLogModal(false);
          openSnackBar("No log found.");
        }

      } else {
        setOnBoardingLogModal(false);
        openSnackBar(respData.msg);
      }
    });
  };
  const changeMagnifierImage = (image, name) => {
    setDocName(name.replace(/_/g, ' '))
    setMagnifierImage(image)
  }

  const fetchCorpDocs = (id) => {
    //function to fetch company docs

    axios.post(`${config.url().baseURL}/get-company-docs/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setCorpDocs(respData.data.Documents)
        fetchCorpDoc(respData.data.Documents[0].DocumentID)
      } else {
        setCorpDocs([])
        openSnackBar("Unable to process.");
      }
    }).catch((e) => {
      setCorpDocs([])
      openSnackBar("Unable fetch documents.")
    })
  }



  const editCompanyData = (id) => {
    setComID(id)
    // setActiveStep(0);
    setComInfoKey(comInfoKey + 1)
    // handleAddClick()
    setCropID(id)

    axios.post(`${config.url().baseURL}/get-company-data/${id}`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setCorpDetails(respData.data);
        respData.data.CompanyInformation.companyID = id
        dispatch(setCompanyInfo(respData.data.CompanyInformation));
        dispatch(setOwnersAndCtrls(respData.data.OwnersAndControllers));
        let businessInfo = {
          BusinessActivityProfile: respData.data.BusinessActivityProfile,
          ClientDeclaration: respData.data.ClientDeclaration,
          AuthorisationAndConfirmation: respData.data.AuthorisationAndConfirmation,
          NameOfThirdParty: !respData.data.AuthorisationAndConfirmation ? respData.data.NameOfThirdParty : "",
          LetterOfRepresentation: respData.data.LetterOfRepresentation,
          AssistedOfficer: respData.data.AssistedOfficer,
          DateOfFirstSalaryTransfer: respData.data.DateOfFirstSalaryTransfer,
          BusinessRegistrationDetails: respData.data.BusinessRegistrationDetails,
          CreatedBy: respData.data.CreatedBy,
          CreatedDate: respData.data.UpdatedDate,
          Status: respData.data.Status,
          SearchFilter: respData.data.SearchFilter,
          TicketID: respData.data.TicketID,
          Document: respData.data.Document,
          Role: respData.data.Role,
          FeeType: respData.data.FeeType,
          m2pWalletCreate:respData.data.m2pWalletCreate,
          m2pWalletStatus:respData.data.m2pWallet.status
        }

        dispatch(setBusinessProfile(businessInfo));
        if (respData.data.ApplicationID == null || respData.data.ApplicationID == "") {
          setCacheProps(true); setEditProps(false)
        }
        else {
          setCacheProps(false); setEditProps(true);
        }
        setShowLoader(false);
        setShowAddCorporate(true);
        setShowCorporateTable(false);
      } else {
        setCorpDetails([]);
        setShowLoader(false);
        setShowCorporateTable(true);
        openSnackBar(respData.msg);
      }
    })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
        setShowCorporateTable(true);
        openSnackBar("Unable to process.");
      });
  }

  const changeWorkFlow = (status, riskStatus, riskServices, services, id) => {
    //function to change  workflow as per company status
    setShowNameScreenBtn(null)
    if (riskStatus) {
      if (riskServices !== undefined && riskServices !== null && riskServices.length !== 0) {
        let riskService = riskServices.find(riskService => riskService.name == "Corporate Onboarding")
        if (riskService !== undefined) {
          switch (status) {
            case "Created":
              if (riskService.type == "checker") {
                setShowActionBtn(true)
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                // setNormalBtn(<Button Button onClick={() => confirmMovingNormal(id)} color="success" > Normal</Button >)
                setShowNameScreenBtn(<Button
                  onClick={() => {
                    setShowNameScreenWithoutApproval(true)
                  }}
                  size="lg"
                  className={classes.topBackButton}
                  simple
                  color="primary">
                  <PageviewIcon />
                  Name Screen
                </Button>)
              } else {
                setNormalBtn(null)
                setRecheckBtn(null)
                setShowActionBtn(false)
              }
              break;
            case "Updated":
              if (riskService.type == "checker") {
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                // setNormalBtn(<Button Button onClick={() => confirmMovingNormal(id)} color="success" > Normal</Button >)
                setShowActionBtn(true)
              } else {
                setNormalBtn(null)
                setRecheckBtn(null)
                setShowActionBtn(false)
              }
              break;
            case "Approved":
              setShowActionBtn(false)
              break;
            case "Checked"://compliance
              if (riskService.type == "checkerTwo") {
                setShowActionBtn(true)
                setShowNameScreenBtn(<Button
                  onClick={() => {
                    openNameScreening(id)
                  }}
                  size="lg"
                  className={classes.topBackButton}
                  simple
                  color="primary">
                  <PageviewIcon />
                  Name Screen
                </Button>)
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                setRejectBtn(<Button color={"danger"} onClick={() => confirmRejection()} style={{ marginLeft: "5px" }} >Reject</Button>)
              } else {
                setRejectBtn(null)
                setRecheckBtn(null)
                setShowActionBtn(false)
              }
              break;
            case "Level Two Checked"://FA
              if (riskService.type == "checkerThree") {
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                setShowActionBtn(true)
              } else {

                setShowActionBtn(false)
              }
              break;
            case "Level Three Checked"://SH
              if (riskService.type == "approver") {
                setRejectBtn(<Button color={"danger"} onClick={() => confirmRejection()} style={{ marginLeft: "5px" }} >Reject</Button>)
                setShowActionBtn(true)
              } else {
                setRejectBtn(null)
                setShowActionBtn(false)
              }
              break;

            default:
              setShowActionBtn(false)
              break;
          }
        }
      } else {
        setShowActionBtn(false)
      }
    } else {
      if (services !== undefined && services !== null && services.length !== 0) {
        let service = services.find(services => services.name == "Corporate Onboarding")
        if (service !== undefined) {
          switch (status) {
            case "Created":
              if (service.type == "checker") {
                setShowActionBtn(true)
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                // setExceptionBtn(<Button Button style={{ marginLeft: "5px" }} onClick={() => confirmMovingToException(id)} color="warning" > Exceptional</Button >)
                setShowNameScreenBtn(<Button
                  onClick={() => {
                    setShowNameScreenWithoutApproval(true)
                  }}
                  size="lg"
                  className={classes.topBackButton}
                  simple
                  color="primary">
                  <PageviewIcon />
                  Name Screen
                </Button>)
              }

              else {
                setExceptionBtn(null)
                setRecheckBtn(null)
                setShowActionBtn(false)
              }
              break;
            case "Updated":
              if (service.type == "checker") {
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                // setExceptionBtn(<Button Button style={{ marginLeft: "5px" }} onClick={() => confirmMovingToException(id)} color="warning" > Execeptional</Button >)
                setShowActionBtn(true)
              } else {
                setRecheckBtn(null)
                setExceptionBtn(null)
                setShowActionBtn(false)
              }
              break;
            case "Rechecked":
              if (service.type == "checker") {
                setShowActionBtn(true)
                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
              } else {
                setShowActionBtn(false)
                setRecheckBtn(null)
              }
              break;
            case "Approved":
              setShowActionBtn(false)
              break;
            case "Checked"://FA
              if (service.type == "checkerTwo") {

                setRecheckBtn(<Button color={"danger"} onClick={() => openRemarks("recheck")} style={{ marginLeft: "5px" }} >Recheck</Button>)
                setShowActionBtn(true)
              } else {
                setShowActionBtn(false)
                setRecheckBtn(null)
              }
              break;

            case "Level Two Checked"://SH
              if (service.type == "approver") {
                setRejectBtn(<Button color={"danger"} onClick={() => confirmRejection()} style={{ marginLeft: "5px" }} >Reject</Button>)
                setShowActionBtn(true)
              } else {
                setShowActionBtn(false)
                setRejectBtn(null);
              }
              break;
            default:
              setShowActionBtn(false)
              setRecheckBtn(null)
              setRejectBtn(null)
              setExceptionBtn(null)
              break;
          }
        }
      } else {
        setShowActionBtn(false)
        setRecheckBtn(null)
        setRejectBtn(null)
        setExceptionBtn(null)
      }
    }
  }

  const fetchCompanyDetails = (id, status, risk_status) => {
    //function to fetch company details
    setShowCorporateTable(false);
    setShowLoader(true);
    setCropID(id)
    axios.post(`${config.url().baseURL}/get-company-data/${id}`)
      .then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setCorpDocs(respData.data.Document)
          fetchCorpDoc(respData.data.Document[0].DocumentID, id)
          setCorpDetails(respData.data);
          let comInfo = respData.data.CompanyInformation
          let m2pWallet = respData.data.m2pWallet
          setCompanyName([
            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Name Of Company</b>, ":", comInfo.NameOfCompany],
            ["ERP NO", ":", respData.data.ERP_NO ? respData.data.ERP_NO : "Not entered"]
          ])

          let regCountryTemp = ""
          let trCountryTemp = ""
          if (countries.find(country => country.ISO == comInfo.RegisteredBusinessAddress.Country)) {
            regCountryTemp = countries.find(country => country.ISO == comInfo.RegisteredBusinessAddress.Country)
          }
          if (countries.find(country => country.ISO == comInfo.TradingAddress.Country)) {
            trCountryTemp = countries.find(country => country.ISO == comInfo.TradingAddress.Country)
          }
          setCompanyInformation([
            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Registered Business Address</b>, "", "", "", "", ""],
            ["Flat No.", ":", comInfo.RegisteredBusinessAddress.FlatNumber, "Building No.", ":", comInfo.RegisteredBusinessAddress.BuildingNumber],
            ["Road No.", ":", comInfo.RegisteredBusinessAddress.RoadNumber, "Block No.", ":", comInfo.RegisteredBusinessAddress.BlockNumber],
            ["Area", ":", comInfo.RegisteredBusinessAddress.Area, "Country", ":", regCountryTemp.country],

            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Trading Address</b>, "", "", "", "", ""],
            ["Flat No.", ":", comInfo.TradingAddress.FlatNumber ? comInfo.TradingAddress.FlatNumber : null, "Building No.", ":", comInfo.TradingAddress.BuildingNumber ? comInfo.TradingAddress.BuildingNumber : null],
            ["Road No.", ":", comInfo.TradingAddress.RoadNumber ? comInfo.TradingAddress.RoadNumber : null, "Block No.", ":", comInfo.TradingAddress.BlockNumber ? comInfo.TradingAddress.BlockNumber : null],
            ["Area", ":", comInfo.TradingAddress.Area ? comInfo.TradingAddress.Area : null, "Country", ":", trCountryTemp.country ? trCountryTemp.country : null],


            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Incorporation Details</b>, "", "", "", "", ""],
            ["RegistrationID", ":", comInfo.IncorporationDetails.RegistrationID, "Expiry Date", ":", comInfo.IncorporationDetails.ExpiryDate],
            ["Date of Incorporation", ":", comInfo.IncorporationDetails.DateOfIncorporation, "Country of Incorporation", ":", comInfo.IncorporationDetails.CountryOfIncorporation],
            ["Listed on Stock Market", ":", comInfo.IncorporationDetails.IdListedOnStock, comInfo.IncorporationDetails.StockListed !== undefined ? "Market Name" : "", comInfo.IncorporationDetails.StockListed !== undefined ? ":" : "", comInfo.IncorporationDetails.StockListed !== undefined ? (
              comInfo.IncorporationDetails.StockListed.join("")) : null],

            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >Regulatory Status</b>, "", "", "", "", ""],
            ["Regulatory Authority", ":", comInfo.RegulatoryStatus.RegulatoryAuthorityName, "License No.", ":", comInfo.RegulatoryStatus.LicenseNumber],
            ["Is any violation on Regulations", ":", comInfo.RegulatoryStatus.IsAnyViolationOnRegulations ? comInfo.RegulatoryStatus.IsAnyViolationOnRegulations : "", "Details", ":", comInfo.RegulatoryStatus.ViolationDetails],
          
            // M2P
            [<b style={{ margin: "0", fontSize: "12px", fontWeight: "500", }} >M2P</b>, "", "", "", "", ""],
            // ["M2P Account Status", ":",m2pWallet?.status,"M2P Wallet Requested", ":", respData.data?.m2pWalletCreate==true?"True":"False", "M2P Mail Send", ":", m2pWallet?.mailSend==true?"TRUE":"FALSE"],
          
            [
              "M2P Wallet Status", ":", respData.data?.m2pWallet?.status,
              respData.data?.m2pWallet?.status !== "ACTIVE" ? " M2P Wallet Requested" : "",
              // Only show the status part if the condition is met
              respData.data?.m2pWallet?.status !== "ACTIVE" ? [
                ":", respData.data?.m2pWalletCreate ? "True" : "False"
              ] : "",  // If m2pWalletCreate is not defined, don't show anything
              "M2P Mail Send", ":", respData.data?.m2pWallet.mailSend !== undefined ? 
              (respData.data?.m2pWallet.mailSend ? "TRUE" : "FALSE") : ""
            ]
          ]);
          let contactData = []
          comInfo.ContactDetails.map((contact) => {
            contactData.push([contact.Title, contact.FullName, contact.Mobile, contact.Email, contact.CPRNumber]);
          });
          setContactInfo(contactData)
          if (respData.data.OwnersAndControllers && respData.data.OwnersAndControllers.Owners) {
            let ownersData = []
            respData.data.OwnersAndControllers.Owners.map((owner) => { ownersData.push([owner.Name, owner.Nationality, owner.CountryOfResidence, owner.CPRNumber]); });
            setOwnerInfo(ownersData)
          } else {
            setOwnerInfo([])
          }
          if (respData.data.OwnersAndControllers.PowerOfAttorney !== undefined) {
            setPorInfo([[respData.data.OwnersAndControllers.PowerOfAttorney.Name, respData.data.OwnersAndControllers.PowerOfAttorney.Nationality, respData.data.OwnersAndControllers.PowerOfAttorney.CountryOfResidence, respData.data.OwnersAndControllers.PowerOfAttorney.CPRNumber]])
          }

          if (respData.data.OwnersAndControllers && respData.data.OwnersAndControllers.DirectorsAndAuthorisedSignatories) {
            let dirData = []
            respData.data.OwnersAndControllers.DirectorsAndAuthorisedSignatories.map((dir) => { dirData.push([dir.Name, dir.Nationality, dir.CountryOfResidence, dir.CPRNumber]); });
            setDirInfo(dirData)
          } else {
            setDirInfo([])
          }

          let pepData = []
          if (respData.data.OwnersAndControllers.AreAnyExecutivesPEPs == "YES" || respData.data.OwnersAndControllers.AreAnyExecutivespeps == "YES") {
            respData.data.OwnersAndControllers.PoliticallyExposedPersons.map((pep) => { pepData.push([pep.LegalName, pep.NameOfPublicEntity, pep.PositionHeld, pep.NatureOfBusiness]); });
          }
          setPepInfo(pepData)
          let businessActivityData = []
          businessActivityData.push([<h6 style={{ margin: "0", fontSize: "13px", fontWeight: "500", textTransform: "none" }} >Business Activity Profile</h6>, "", "", "", ""],)
          businessActivityData.push(["Nature of Business", ":", respData.data.BusinessActivityProfile.NatureOfBusiness, ""])
          businessActivityData.push(["Source of Fund", ":", respData.data.BusinessActivityProfile.SourceOfFunds, ""])
          setBusinessInfo(businessActivityData)
          setBankInfo([["Bank Name", ":", respData.data.BusinessRegistrationDetails.BankName, "Account Name", ":", respData.data.BusinessRegistrationDetails.AccountName, "IBAN No", ":", respData.data.BusinessRegistrationDetails.AccountNumber, "Vat Number ", ":", respData.data.BusinessRegistrationDetails.vat_number]])
          let declarationData = []
          let authDeclaration
          if (respData.data.AuthorisationAndConfirmation) {
            authDeclaration = "Yes"
          } else {
            authDeclaration = "No"
          }
          declarationData.push([<h6 style={{ margin: "0", fontSize: "13px", fontWeight: "500", textTransform: "none" }} >Client Declaration</h6>, "", "", "", ""],)
          declarationData.push(["Name", ":", respData.data.ClientDeclaration.Name, "Designation", ":", respData.data.ClientDeclaration.Designation],)
          declarationData.push(["Authorization and Confirmation", ":", authDeclaration, "", "", ""],)
          setDeclarationInfo(declarationData)

          let lorData = []
          let lorList = {}
          if (respData.data.LetterOfRepresentation) {
            respData.data.LetterOfRepresentation.map((lor) => {
              lorData.push([lor.Name, lor.Designation, lor.CPRNumber, lor.EmailAddress, lor.MobileNumber, lor.LandLine ? lor.LandLine : ""]);
              lorList[`${lor.CPRNumber}`] = false
            });
          }
          let feeData = []
          if (respData.data.FeeType == "monthly" && respData.data.monthlyFeeTax != (undefined || null || "")) feeData.push(["Type", ":", "Monthly", "Service Fee", ":", respData.data.Fee, "\
          ", ":", "Card Fee", ":", respData.data.CardFee, "Tabiib Card fee", ":", respData.data.tabiibCardFee])
          else if (respData.data.FeeType == "monthly") feeData.push(["Type", ":", "Monthly", "Service Fee", ":", respData.data.Fee, "Card Fee", ":", respData.data.CardFee, "Tabiib Card fee", ":", respData.data.tabiibCardFee])
          else if (respData.data.FeeType == "no fee") feeData.push(["Type", ":", "No Fee", "Service Fee", ":", respData.data.Fee, "Card Fee", ":", respData.data.CardFee, "Tabiib Card fee", ":", respData.data.tabiibCardFee])
          else feeData.push(["Type", ":", "Per Employee Service", "Service Fee", ":", respData.data.Fee, "Card Fee", ":", respData.data.CardFee, "Tabiib Card fee", ":", respData.data.tabiibCardFee])
          if (respData.data.FeeTax != (undefined || null)) feeData.push(["VAT", ":", respData.data.FeeTax, "MDC", ":", respData.data.mdcCardFee])
          setFeeInfo(feeData)
          setLorEnabled(lorList)
          setLorData(respData.data.LetterOfRepresentation)
          setLorInfo(lorData)
          setRiskStatus(respData.data.RiskStatus)
          changeWorkFlow(status, respData.data.RiskStatus, user.riskServices, user.services, id)
          setShowLoader(false);
          dispatch(setBusinessProfile(businessInfo));
          setShowCorpDetails(true);
        } else {
          setCorpDetails([]);
          setShowLoader(false);
          setShowCorporateTable(true);
          openSnackBar(respData.msg);
        }

      })
      .catch((err) => {
        // corporate onboarding view log error
        console.log(err);
        setShowLoader(false);
        setShowCorporateTable(true);

        openSnackBar("Unable to process.");

      });

  };

  const handleAddClick = () => {
    //function to handle add button click
    setShowCorporateTable(false);
    setShowAddCorporate(true);
  };

  const handleBackClick = () => {
    //function to handle back button click
    setShowAddCorporate(false);
    setShowCorporateTable(true);
    setShowCorpDetails(false)
    setCorpDetails([])
    setLorInfo([])
    setCompanyInformation([])
    setDeclarationInfo([])
    setBusinessInfo([])
    setOwnerInfo([])
    setCorpDocs([])
    handleReset();
    fetchCorporateData();
    scrollTop()
  };
  function getSteps() {
    return ["Company Information", "Owners and Controllers", "Other Details & KYC Documents"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        //return <OwnersAndControllers nextStep={handleNext} />;
        //return <KYCDocuments nextStep={handleNext} />;
        return <CompanyInformation key={comInfoKey} showPage={true} back={backProps} cacheEdit={cacheEditProps} id={comID} edit={editProps} previousStep={handleBack} nextStep={handleNext} />;
      case 1:
        return <OwnersAndControllers key={comInfoKey} back={backProps} id={comID} edit={editProps} cacheEdit={cacheEditProps} previousStep={handleBack} showPage={true} nextStep={handleNext} />;
      case 2:
        return <KYCDocuments key={comInfoKey} edit={editProps} id={comID} previousStep={handleBack} cacheEdit={cacheEditProps} showPage={true} reset={handleReset} />;
      default:
        return "Unknown stepIndex";
    }
  }

  const handleNext = (status, id) => {
    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (status) {
      // setBackProps(true)
      // setComID(id)
      if (activeStep + 1 == 1) {
        axios.get(`${config.url().baseURL}/get-company-onboarding-logs/${id}`).then((resp) => {
          let respData = resp.data;
          if (!respData.err) {
            if (respData.data[0].log[respData.data[0].log.length - 1].status == "Step One Completed") {
              setBackProps(false)
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
              setBackProps(true)
              setComID(id)
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }
        });
      } else {
        setBackProps(true)
        setComID(id)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setBackProps(false)
      setComID("")
    }
    if (editProps && corpID) {
      editCompanyData(corpID)
    }
  };

  const handleBack = (back, id) => {
    setComInfoKey(comInfoKey + 1)
    if (activeStep == 0) {
      handleReset()
      setBackProps(false)
      setComID(id)
    } else {
      if (back) {
        setBackProps(back)
        setComID(id)
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    if (editProps) {
      editCompanyData(corpID)
      // editCompanyData(comID)
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setShowAddCorporate(false);
    setShowCorporateTable(true);
    setShowCorpDetails(false)
    setEditProps(false)
    setCorpDetails([])
    setLorInfo([])
    setCompanyInformation([])
    setDeclarationInfo([])
    setBusinessInfo([])
    setOwnerInfo([])
    setCorpDocs([])
    fetchCorporateData();
    dispatch(setCompanyInfo([]));
    dispatch(setOwnersAndCtrls([]));
    dispatch(setBusinessProfile([]));
  };

  const changeLorStatus = (id, status) => {
    //function to change lor status
    let newStatus = !status
    // alert(id)
    // alert(newStatus)
  }


  const makeCorpExceptional = (id) => {
    //function to move corporate to execeptional flow
    if (!submitting) {
      setSubmitting(true)
      axios.put(`${config.url().baseURL}/make-company-exceptional/${id}`, {}, { headers: { auth: sessionStorage.getItem("cx_id") }, }).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setOpenRemarksModal(false)
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title={respData.msg}
              onConfirm={() => {
                setRespAlert(null);
                setSubmitting(false)
                openRemarks("approve")
              }
              }
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );
        } else {
          openSnackBar(respData.msg);
          setOpenRemarksModal(false)
          setSubmitting(false);
        }
      })
        .catch((err) => {
          openSnackBar("Unable to process.");
        });
    }
  }

  const makeCorpNormal = (id) => {
    //function to move corporate to normal flow
    if (!submitting) {
      setSubmitting(true)
      axios.put(`${config.url().baseURL}/make-company-normal/${id}`, {}, { headers: { auth: sessionStorage.getItem("cx_id") }, }).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setOpenRemarksModal(false)
          setRespAlert(
            <SweetAlert
              success
              style={{ display: "block" }}
              title={respData.msg}
              onConfirm={() => {
                setRespAlert(null);
                setSubmitting(false)
                handleBackClick()
              }
              }
              confirmBtnCssClass={classes.button + " " + classes.success}
            />
          );
        } else {
          openSnackBar(respData.msg);
          setOpenRemarksModal(false)
          setSubmitting(false);
        }
      })
        .catch((err) => {
          openSnackBar("Unable to process.");
        });
    }
  }

  const enableLorLogin = (cpr, status) => {
    //function to enable lor login for each lor 
    let lorEnabledTemp = lorEnabled
    lorEnabledTemp[`${cpr}`] = status
    setLorEnabled(lorEnabledTemp)
    setFlag(!flag)
  }

  const confirmMovingToException = (id) => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setRespAlert(null);
          makeCorpExceptional(id)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        Do you really want to move this corporate to exceptional flow?
      </SweetAlert>)
  }

  const confirmMovingNormal = (id) => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setRespAlert(null);
          makeCorpNormal(id)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Do you really want to move this corporate to normal flow?
      </SweetAlert>)
  }
  const confirmRejection = () => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setRespAlert(null);
          openRemarks("Rejected")
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.danger}
      >
        Do you really want to hard reject this corporate?
      </SweetAlert>)
  }

  const scrollTop = () => {
    //function to scroll top
    scrollRef.current.click()
  }

  const checkNameScreeningStatus = (status) => {
    setNameScreeningStatus(status)
  }

  const openNameScreening = (id) => {
    //function to open name screening
    setShowNameScreen(true)
    setShowCorpDetails(false)
    setNameScreen(<NameScreen corpID={id} checkStatus={checkNameScreeningStatus} />)
  }



  const confirmRetryCorporate = (id) => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setRespAlert(null);
          retryCorporate(id)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Do you really want to retry this corporate?
      </SweetAlert>)
  }

  const retryCorporate = (id) => {
    setRespAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Retry initiated."
        onConfirm={() => setRespAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
    axios.post(`${config.url().baseURL}/kyc-retry-corporate/${id}`)
      .then((resp) => {
      }).catch((e) => {
      })

  }
  return (
    <div id="top" className={classes.root}>
      {respAlert}
      <Collapse in={showAddCorporate} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
        <div>
          <h3 className={classes.mainHeadArea}>Corporate Onboarding</h3>
        </div>
        <Stepper style={{ backgroundColor: "transparent" }} activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel style={{ backgroundColor: "transparent", color: "white !important" }}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div>
          {activeStep === steps.length ? (
            <div>
              {/* <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button> */}
            </div>
          ) : (
            <div>
              <div className={classes.instructions}>{getStepContent(activeStep)}</div>
              {/* <div>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </div> */}
            </div>
          )}
        </div>
        <Collapse
          in={activeStep == 0}
          style={{ transformOrigin: "0 0 0", textAlign: "center" }}
          {...(true ? { timeout: 500 } : {})}>
          <a ref={scrollRef} href="#top" style={{ display: "none" }}>test</a>
          <Button
            onClick={() => {
              handleBackClick();
            }}
            size="lg"
            className={classes.backButton}
            simple
            color="primary">
            <ArrowBackIcon />
            Back
          </Button>
        </Collapse>
      </Collapse>

      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>

          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showCorporateTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Companies</h4>
                {showAddCorpBtn ? (
                  <Button onClick={() => handleAddClick()} color="secondary" size="sm" className={classes.addButton}>
                    Add Corporate
                  </Button>
                ) : null}

              </CardHeader>
              <CardBody>
                <MUIDataTable data={corporateData} columns={columns} options={options} />
              </CardBody>
            </Card>
          </Collapse>
        </GridItem>
      </GridContainer>
      <Collapse
        in={showCorpDetails}
        style={{ transformOrigin: "0 0 0" }}
        {...(true ? { timeout: 500 } : {})}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <h6 className={classes.corpTopHead}>Application No : {corpDetails.ApplicationID} </h6>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <h6 className={classes.corpTopHead}>Reference Number No : {corpDetails.ReferenceNumber} </h6>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Button
              onClick={() => {
                handleBackClick();
              }}
              size="lg"
              className={classes.topBackButton}
              simple
              color="primary">
              <ArrowBackIcon />
              Back
            </Button>
            {showNameScreenBtn}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>

            <Card plain  >
              <CardBody className={classes.detailsContainer}>
                <Table tableData={companyName} />
                <Table tableData={companyInfo} />
                <h6 className={classes.corpDetailsTableHead} > Contact Details </h6>
                <Table tableHeaderColor="primary" tableHead={["Title", "Name", "Mobile", "Email", "CPR"]} tableData={contactInfo} />
                <h6 className={classes.corpDetailsTableHead} >Owners and Controllers</h6>
                <Table tableHeaderColor="primary" tableHead={["Name", "Nationality", "Residing Country", "CPR"]} tableData={ownerInfo} />
                <h6 className={classes.corpDetailsTableHead} >Power of Attorney</h6>
                <Table tableHeaderColor="primary" tableHead={["Name", "Nationality", "Residing Country", "CPR"]} tableData={porInfo} />
                <h6 className={classes.corpDetailsTableHead} >Directors and Authorized Signatories</h6>
                <Table tableHeaderColor="primary" tableHead={["Name", "Nationality", "Residing Country", "CPR"]} tableData={dirInfo} />
                {pepInfo.length !== 0 ? (
                  <>
                    <h6 className={classes.corpDetailsTableHead} >Publicly Exposed Persons</h6>
                    <Table tableHeaderColor="primary" tableHead={["Legal Name", "Public Entity", "Position", "Nature Of Business"]} tableData={pepInfo} />
                  </>
                ) : null}
                <Table tableData={businessInfo} />
                <Table tableData={declarationInfo} />
                <h6 className={classes.corpDetailsTableHead} >Letter of Representation</h6>
                <Table tableHeaderColor="primary" tableHead={["Name", "Designation", "CPR", "Email", "Mobile", "LandLine"]} tableData={lorInfo} />
                <h6 className={classes.corpDetailsTableHead} >Bank Details</h6>
                <Table tableData={bankInfo} />
                <h6 className={classes.corpDetailsTableHead} >Fee Details</h6>
                <Table tableData={feeInfo} />
              </CardBody>
            </Card>
            {showActionBtn ? (
              <div style={{ textAlign: "center" }}>
                {rejectBtn}
                {normalBtn}
                {recheckBtn}
                {exceptionBtn}
                <Button style={{ marginRight: "5px", marginLeft: "5px" }} onClick={() => openRemarks("approve")} color="primary">Approve</Button>
              </div>
            ) : null}

          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {magnifierImage !== null ? (
              <>
                <h6 className={classes.imageName} >{docName}</h6>
                {showImage ? (
                  <>
                    <Magnifier src={magnifierImage} width="100%" />
                  </>
                ) : (
                  <>
                    <div class="employeePDFcanvas" style={{ textAlign: 'center', overflow: 'scroll' }}>
                      <Document
                        file={magnifierImage}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} scale={pdfZoom} />
                      </Document>
                      <p>
                        Page {pageNumber} of {numPages}
                      </p>
                      <div>
                        <ZoomInIcon
                          style={{ marginBottom: '-4px', background: '004fa4', marginLeft: '20px', fontSize: '30px' }}
                          onClick={handleZoomIn}
                        />
                        <ZoomOutIcon
                          style={{ marginBottom: '-4px', background: '004fa4', marginLeft: '7px', fontSize: '30px' }}
                          onClick={handleZoomOut}
                        />
                      </div>
                      <Button size="sm" color="primary" onClick={() => {
                        if (pageNumber < 2) {
                          setPageNumber(1)
                        } else {
                          let newPageNumber = pageNumber - 1
                          setPageNumber(newPageNumber)
                        }
                      }} >Pervious</Button>
                      <Button size="sm" color="primary"
                        onClick={() => {
                          if (numPages > pageNumber) {
                            let newPageNumber = pageNumber + 1
                            setPageNumber(newPageNumber)
                          } else {
                            setPageNumber(numPages)
                          }
                        }}
                      >Next</Button>
                    </div>
                  </>)}
              </>
            ) : (<>
              <Skeleton variant="text" width={500} />
              <Skeleton variant="react" height={500} />
              <Skeleton variant="react" height={60} />

            </>)}
            <GridContainer>
              {corpDocs.map((doc) => {
                return (
                  <GridItem xs={12} sm={12} size="sm" md={12}>
                    <Button onClick={() => fetchCorpDoc(doc.DocumentID, corpID)} fullWidth color="primary">{doc.DocumentType.replace(/_/g, ' ')}</Button>
                  </GridItem>
                )
              })}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Collapse>
      <Collapse
        in={showNameScreen}
        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
        {...(true ? { timeout: 500 } : {})}>
        <Card>
          <CardBody>
            {nameScreen}
            <Button
              onClick={() => {
                setShowNameScreen(false)
                setNameScreen(null)
                setShowCorpDetails(true)
              }}
              simple
              color="danger"
              size="md">
              Close
            </Button>
          </CardBody>
        </Card>

      </Collapse>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.companyLogModal,
        }}
        open={logModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setLogModal(false);
          setLogData([]);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Company Log</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <Collapse
            in={logData.length == 0}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
          <Collapse
            in={logData.length}
            style={{ transformOrigin: "0 0 0" }}
            {...(true ? { timeout: 500 } : {})}>
            <div className={classes.logContainer}>
              {logData.map((log) => {
                return (
                  <>
                    {log._id ? (
                      <>
                        Log ID :{log._id}
                        <br />
                      </>
                    ) : null}
                    {log.time ? (
                      <>
                        Date : {moment(log.time).format("DD/MM/YYYY")}
                        <br />
                        Time : {moment(log.time).format("HH:MM:SS")}
                        <br />
                      </>
                    ) : null}

                    {log.url_called ? (
                      <>
                        URL : {log.url_called}
                        <br />
                      </>
                    ) : null}
                    {log.ReferenceNumber ? (
                      <>
                        Reference eNumber : {log.ReferenceNumber}
                        <br />
                      </>
                    ) : null}
                    {log.result ? (
                      <>
                        Result : {log.result}
                        <br />
                      </>
                    ) : null}
                    {log.requestBodySent ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography>Sent Body Data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JSONPretty data={log.requestBodySent} />
                        </AccordionDetails>
                      </Accordion>
                    ) : null}
                    {log.response ? (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
                          <Typography>Response</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <JSONPretty data={log.response} />
                        </AccordionDetails>
                      </Accordion>
                    ) : null}
                    <br />
                    <Divider />
                    <br />
                  </>
                );
              })}
            </div>
          </Collapse>
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setLogModal(false);
              setLogData([]);
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.companyStatusLogModal,
        }}
        open={onBoardingLogModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOnBoardingLogModal(false);
          setOnBoardingLogs([]);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Company Status Log</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <TableContainer className={classes.logContainer}>
            <MuiTable stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {logColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {onBoardingLogs.map((row, key) => {

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        {moment(row.date).format("hh:mm:ss A")}
                      </TableCell>
                      <TableCell>
                        {row.user ? row.user.firstName + " " + row.user.lastName : null}
                      </TableCell>
                      <TableCell>
                        {row.status}
                      </TableCell>
                      <TableCell>
                        {row.status_message}
                      </TableCell>
                      <TableCell>
                        {row.remarks}
                      </TableCell>

                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setOnBoardingLogModal(false);
              setOnBoardingLogs([]);
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.modalMedium,
        }}
        open={openRemarksModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpenRemarksModal(false);
          setRemarks("");
          setErpCode("")
          setShowErp(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>Please enter remarks</h4>
        </DialogTitle>
        <DialogContent id="classic-modal-slide-description" className={classes.modalBodyRemarks}>
          <CustomInput
            labelText="Remarks"
            error={remarksErr}
            id="remarks"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: remarks,
              onChange: (e) => {
                setRemarks(e.target.value);
                setRemarksErr(false);
              },
            }}
          />
          {showErp ? (

            <CustomInput
              labelText="ERP Code"
              error={erpCodeErr}
              id="erp"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: erpCode,
                // disabled:true
                onChange: (e) => {
                  // setErpCode(e.target.value);
                  setErpCodeErr(false);
                },
              }}
            />
          ) : null}
          {showEnableLogin ? (
            <>
              <br /> <br />
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Enable Login for Legal Reps.</FormLabel>
                <FormGroup>
                  {lorData.map((lor) => {
                    return <FormControlLabel
                      control={<Checkbox color="primary" checked={lorEnabled[`${lor.CPRNumber}`]} onChange={(e) => enableLorLogin(lor.CPRNumber, e.target.checked,)} name={lor.Name} />}
                      label={lor.Name}
                    />
                  })}
                </FormGroup>
              </FormControl>
            </>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setOpenRemarksModal(false);
              setRemarks("");
              setErpCode("")
            }}
            simple
            color="danger"
            size="md">
            Cancel
          </Button>
          <Button
            onClick={() => {
              changeStatus()
            }}
            color="primary"
            size="md">
            {!submitting ? (
              "Save"
            ) : (
              <PulseLoader color="#fff" loading={true} css={override} size={5} margin={2} />
            )}
          </Button>
        </DialogActions>
      </Dialog>


      {/* <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.transactionsModal,
        }}
        open={showNameScreen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowNameScreen(false)
          setNameScreen(null)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {nameScreen}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setShowNameScreen(false)
              setNameScreen(null)
            }}
            simple
            color="danger"
            size="md">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        classes={{
          root: classes.left + " " + classes.modalRoot,
          paper: classes.transactionsModal,
        }}
        open={showNameScreenWithoutApproval}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowNameScreenWithoutApproval(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          <NameScreenWithoutApprove corpID={corpID} />
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              setShowNameScreenWithoutApproval(false)
            }}
            simple
            color="danger"
            size="md">
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </div >
  );
}
