import React, { useState } from 'react';
import { Grid, Paper, InputBase, Button, Tooltip, IconButton, Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    addDeptButton: {
        // marginRight: "-5px !important",
        backgroundColor: '#0057b8 !important', // Update this to the specific shade of blue you want
        color: 'white',
        '&:hover': {
            backgroundColor: '#004494', // A darker shade for the hover effect
        },
        minWidth: '48px',

    },
    select: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    resetButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    select: {
        '&:before': {
            borderColor: 'transparent',
        },
        '&:after': {
            borderColor: 'transparent',
        },
        '& .MuiSelect-root': {
            padding: '8px',
        },
        '& .MuiSelect-selectMenu': {
            padding: '8px',
        },
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
        '& .MuiInputBase-input': {
            padding: '8px',
        },
    },
}));

export default function Header(props) {
    const classes = useStyles();
    // const [fromDate, setFromDate] = useState('');
    // const [toDate, setToDate] = useState('');
    // const [type, setType] = useState('');
    // const [biller, setBiller] = useState('');
    const {
        onChangeFilter,
        fromDate,
        status,
        toDate,
        crNumber,
        filterCorprateLoad
    } = props
    const resetSearch = () => {
        onChangeFilter('', 'reset')
        filterCorprateLoad({})
        // setType('');
        // setBiller('');
    };

    // const loadRequestpage = () => {
    //     // Implement your search logic here
    // };

    const openSnackBar = (message) => {
        // Implement your snackbar logic here
    };

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={2}>
                    <p className={classes.searchHead}>From Date</p>
                    <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                        <InputBase
                            autoFocus
                            fullWidth
                            className={classes.input}
                            inputProps={{
                                type: "date",
                                max: moment(new Date()).format('YYYY-MM-DD'),
                                onChange: (event) => { onChangeFilter(event, "fromDate") },
                                onBlur: (event) => {
                                    if (moment(event.target.value).isAfter(moment(new Date()))) {
                                        // setFromDate("")
                                        openSnackBar("Please enter valid date.")
                                    } else {
                                        // setFromDate(event.target.value)
                                    }
                                },
                                value: fromDate,
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <p className={classes.searchHead}>To Date</p>
                    <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                        <InputBase
                            fullWidth
                            className={classes.input}
                            inputProps={{
                                type: "date",
                                max: moment(new Date()).format('YYYY-MM-DD'),
                                onChange: (event) => { onChangeFilter(event, "toDate") },
                                onBlur: (event) => {
                                    if (moment(event.target.value).isAfter(moment(new Date()))) {
                                        // setToDate("")
                                        openSnackBar("Please enter valid date.")
                                    } else {
                                        // setToDate(event.target.value)
                                    }
                                },
                                value: toDate,
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <p className={classes.searchHead}>Status</p>
                    {/* <Paper component="form" elevation={1} className={classes.addDeptContainer}> */}
                        <FormControl fullWidth style={{backgroundColor:"white"}}>
                            <Select
                                value={status}
                                onChange={(event) => { onChangeFilter(event, "status") }}

                                displayEmpty
                                className={classes.select}
                            >
                                <MenuItem value="">ALL</MenuItem>
                                <MenuItem value="SUCCESSFUL">SUCCESS</MenuItem>
                                <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                                <MenuItem value="INPROGRESS">INPROGRESS</MenuItem>
                                <MenuItem value="FAILED">FAILED</MenuItem>
                                <MenuItem value="DECLINED">DECLINED</MenuItem>

                            </Select>
                        </FormControl>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <p className={classes.searchHead}>Search CR Number</p>
                    <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                        <FormControl fullWidth>
                            <TextField
                                value={crNumber}
                                onChange={(event) => onChangeFilter(event, 'crNumber')}
                                placeholder="Enter CR Number"
                                className={classes.textField}
                            />
                        </FormControl>
                        <Button className={classes.addDeptButton} onClick={() => filterCorprateLoad({ crNumber, fromDate, toDate, status })}
                            size="lg" color="primary">
                            <SearchIcon fontSize="medium" />
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
            <div className={classes.resetButtonContainer}>
                <Tooltip title="Reset">
                    <IconButton onClick={resetSearch}>
                        <RotateLeftIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}
