import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import config from "config.js"
import SweetAlert from "react-bootstrap-sweetalert";
import { css } from "@emotion/core";
import download from "js-file-download";
import PropagateLoader from "react-spinners/PropagateLoader";
import BounceLoader from "react-spinners/BounceLoader";
import { creatCsvFile, downloadFile } from "download-csv";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import TimelineIcon from '@mui/icons-material/Timeline';
import ListAltIcon from "@material-ui/icons/ListAlt";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// import Table from "components/Table/Table.js";
import Collapse from "@material-ui/core/Collapse";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import ReplayIcon from '@material-ui/icons/Replay';
import TimelineIcon from '@material-ui/icons/Timeline';
import DescriptionIcon from '@material-ui/icons/Description';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";
import Button from "components/CustomButtons/Button.js";
import GetAppIcon from '@material-ui/icons/GetApp';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import Logs from "./Components/Logs.js"
import Transactions from "./Components/Transactions.js"
import NameScreen from "./Components/NameScreen.js"
import FailedLog from "./Components/FailedUserLogs.js"
import EditForm from "./Components/MobileEditForm.js"
import CustomerDetails from "./Components/CustomerDetails.js"
import ShuftiDetails from "views/Customers/Components/ShuftiDetails.js"
import { DoorBack } from "@mui/icons-material";
import AuditLog from "./Components/AuditLog.js";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from "components/Table/Table.js";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

const accountBalanceCSS = css`
display: block;
margin: 0px 25px;

`;
export default function Customers() {
  const classes = useStyles();
  const users = useSelector(state => state.user);
  const [customers, setCustomers] = React.useState([]);
  const [userID, setUserID] = React.useState(null);
  const [shuftiModal, setShuftiModal] = React.useState(false);
  const [showCustomersTable, setShowCustomersTable] = React.useState(false);
  const [showLogModal, setShowLogModal] = React.useState(false);
  const [showTransactionsModal, setShowTransactionsModal] = React.useState(false);
  const [logUserType, setLogUserType] = React.useState("");

  const [showLoader, setShowLoader] = React.useState(true);
  const [shuftiData, setShuftiData] = React.useState([]);
  const [shuftiView, setShuftiView] = React.useState(null);
  const [userDetails, setUserDetails] = React.useState([]);

  const [respAlert, setRespAlert] = React.useState(null);
  const [snackBarMsg, setSnackBarMsg] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);

  const [screeningModel, setScreeningModel] = React.useState(null);
  const [logModel, setLogModel] = React.useState(null);
  const [transactionModel, setTransactionModel] = React.useState(null);
  const [showScreeningModel, setShowScreeningModel] = React.useState(false);

  const [auditLog, setAuditLog] = React.useState([])
  const [auditLogModel, setAuditLogModel] = React.useState(null);
  const [showAuditLogModel, setShowAuditLogModel] = React.useState(false);

  const [showFailedLogs, setShowFailedLogs] = React.useState(false);
  const [failedLogs, setFailedLogs] = React.useState(null);

  const [accountBalance, setAccountBalance] = React.useState("");
  const [walletType, setWalletType] = React.useState("");
  const [walletNo, setWalletNo] = React.useState("");
  const [employerName, setEmployerName] = React.useState("");
  const [customerDOB, setCustomerDOB] = React.useState("");
  const [customerMobile, setCustomerMobile] = React.useState("");
  const [userName, setUserName] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");

  const [showDownload, setShowDownload] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));
  const [toDate, setToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));

  const [maxToDate, setMaxToDate] = React.useState(moment(new Date).format("YYYY-MM-DD"));

  const [showEdit, setShowEdit] = React.useState(false);
  const [editForm, setEditForm] = React.useState(null);

  const [detailsModal, setDetailsModal] = React.useState(false);
  const [detailsView, setDetailsView] = React.useState(null);


  const [userId, setUserId] = React.useState('');


  const [pageno, setPageno] = React.useState('');
  const [logId, setLogId] = React.useState('');
 

  const [mremainingDocs, setMRemainingDocs] = React.useState(false);
  const [mpageValue, setMPageValue] = React.useState(0);





  const auditLogColumn = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'field', label: 'Field', width: 70 },
    { id: 'user', label: 'User', width: 70 },
    { id: 'userType', label: 'User Type', width: 70 },
    { id: 'actions', label: 'Action', minWidth: 70 }


  ]

  const columns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cpr",
      label: "CPR",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userType",
      label: "User Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "updatedStatusShufti",
      label: "Shufti status/App User",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "convertedStatusIGA",
      label: "IGA Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "convertedStatusNetsys",
      label: "Netsys Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "convertedStatusUser",
      label: "User Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "convertedComplianceStatus",
      label: "Compliance Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: " Actions",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const options = {
    selectableRows: false,
    elevation: 0,
    print: false,
    onDownload: () => {
      let csvFile = creatCsvFile(csvData);
      let fileName = "customers.csv";
      downloadFile(csvFile, fileName);
      return false;
    },
    textLabels: {
      body: {
        noMatch: "No matching records found",
      },
    }
  };

  React.useEffect(() => {
    fetchData();
    setSearchKey("")
  }, []);
  let service = users.services.find(service => service.name == "Customers")
  const hideAlert = () => {
    setRespAlert(null);
  };

  function openSnackBar(msg) {
    //function to tost snackbar message
    setOpenSnack(true);
    setSnackBarMsg(msg);
    setTimeout(function () {
      setOpenSnack(false);
      setSnackBarMsg("");
    }, 5000);
  }
  const fetchData = () => {
    //function to fetch customers data
    axios.get(`${config.url().baseURL}/get-users`).then((resp) => {
      let respData = resp.data;
      if (!respData.err) {
        setCustomerData(respData.data)
      } else {
        setCustomers([]);
        setShowLoader(false);
        setShowCustomersTable(true);
        openSnackBar(respData.msg);
      }
    })
      .catch((err) => {
        setCustomers([]);
        setShowLoader(false);
        setShowCustomersTable(true);
        console.log(err)
        openSnackBar("Unable to process");

      });
  };

  const openScreeningModal = (cpr, id) => {
    //function to open Screen modal
    setShowScreeningModel(true)
    setScreeningModel(<NameScreen cpr={cpr} close={closeScreenModal} id={id} type="customer" />)
  }
  const closeScreenModal = () => {
    //function to close name screen modal
    setShowScreeningModel(false)
  }

  const openLogModal = (id, userType) => {
    setLogModel(<Logs userID={id} userType={userType} />)
    setUserID(id)
    setShowLogModal(true);
    setLogUserType(userType)
    console.log(userType, "userType");


  }

  const openAuditLogModal = (userId, logId) => {
    // console.log(setUserID);
    //function to open log modal
    setLogModel(<AuditLog userId={userId} logId={logId} />)
    // setUserI(userId)
    setShowLogModal(true);
    setLogUserType(logId)

  }


  const openTransactionsModel = (id, cpr) => {
    //function to open transaction modal
    setTransactionModel(<Transactions userID={id} cpr={cpr} />)
    setUserID(id)
    console.log(id, 'ID')
    setShowTransactionsModal(true)

  }
  const openAuditLogModel = () => {
    setAuditLogModel()
    setShowAuditLogModel(true)

  }
  const closeTransactionModal = () => {
    setShowTransactionsModal(false);
    setTransactionModel(<Transactions userID={null} cpr={null} />)
  }

  const closeAuditLogModal = () => {
    setAuditLogModel(false);
    setShowAuditLogModel()
  }
  const closeLogModal = () => {
    setShowLogModal(false);
    setLogModel(<Logs userID={null} userType={null} />)
  }

  const setCustomerData = (data) => {
    //function to set Customer Data to table
    //try {


    var updatedData = data?.map((customer, key) => {
      customer.date = `${moment(customer.updatedAt).format("DD/MM/YYYY ")} ${moment(customer.updatedAt).format("hh:mm:ss A")} `
      customer.userShotID = customer.userID ? customer.userID.substring(0, 6) + "..." : ""
      // customer.userType = (customer.minorUser && customer.minorUser == true) ? "GPW Minor Onboarded" : customer.userType
      if (customer.statusShufti === 4 && customer.statusUser) {
        customer.actions = (
          <>

            <Tooltip placement="top" title="View">
              <IconButton
                onClick={() => fetchUserDetails(customer.userID)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Shufti Data">
              <IconButton
                onClick={() => fetchShuftiData(customer.refShufti)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <ImageSearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Logs">
              <IconButton
                onClick={() => openLogModal(customer.userID, customer.userType)
                }
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <TimelineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Transactions">
              <IconButton
                onClick={() => openTransactionsModel(customer.userID, customer.cpr)
                }
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <ListAltIcon />
              </IconButton>
            </Tooltip>
            {(service.type != "viewer") ?
              <Tooltip placement="top" title="Edit Mobile">
                <IconButton
                  onClick={() => { openEditEmployee(customer) }}
                  style={{ marginTop: "-10px", marginBottom: "-10px" }}
                >
                  <PhoneIphoneIcon />
                </IconButton>
              </Tooltip> : null}
            <Tooltip placement="top" title='Audit Log'>
              <IconButton
                onClick={() => auditModelLog(customer.userID, 0)}
                style={{ marginTop: "-11px", marginBottom: '-11px' }}
                aria-label="Audit log"
              >
                <VerifiedUserIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
      else if (customer.statusShufti !== 4 && customer.statusUser) {
        console.log(customer, "303");
        customer.actions = (
          <>
            <Tooltip placement="top" title="View">
              <IconButton
                onClick={() => fetchUserDetails(customer.userID)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Logs">
              <IconButton
                onClick={() => openLogModal(customer.userID, customer.userType)
                }
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <TimelineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Transactions">
              <IconButton
                onClick={() => openTransactionsModel(customer.userID, customer.cpr)
                }
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <ListAltIcon />
              </IconButton>
            </Tooltip>
            {(service.type != "viewer") ?
              <Tooltip placement="top" title="Edit Mobile">
                <IconButton
                  onClick={() => { openEditEmployee(customer) }}
                  style={{ marginTop: "-10px", marginBottom: "-10px" }}
                >
                  <PhoneIphoneIcon />
                </IconButton>
              </Tooltip> : null}
            <Tooltip placement="top" title='Audit Log'>
              <IconButton
                onClick={() => auditModelLog(customer.userID, 0)}
                style={{ marginTop: "-11px", marginBottom: '-11px' }}
                aria-label="Audit log"
              >
                <VerifiedUserIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
      else if (customer.statusShufti === 4) {
        customer.actions = (
          <>
            <Tooltip placement="top" title="View">
              <IconButton
                onClick={() => fetchUserDetails(customer.userID)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title="Shufti Data">
              <IconButton
                onClick={() => fetchShuftiData(customer.refShufti)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <ImageSearchIcon />
              </IconButton>
            </Tooltip>


            {(service.type != "viewer") && (customer.userType == 'Shufti Initiated Signup' || customer.userType == 'Avenues Migrate - Initiate' || customer.userType == 'Fossil Migrate - Initiate') ? (
              <Tooltip placement="top" title="Retry SignUp">
                <IconButton
                  onClick={() => confirmRetryShufti(customer)}
                  style={{ marginTop: "-10px", marginBottom: "-10px" }}
                  aria-label="log">
                  <ReplayIcon />
                </IconButton>
              </Tooltip>

            ) : null}
            <Tooltip placement="top" title="Logs">
              <IconButton
                onClick={() => openLogModal(customer.userID, customer.userType)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <TimelineIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      } else {
        customer.actions = (
          <>
            <Tooltip placement="top" title="View">
              <IconButton
                onClick={() => fetchUserDetails(customer.userID)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="view">
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            {(service.type != "viewer") && (customer.userType == 'Shufti Initiated Signup' || customer.userType == 'Avenues Migrate - Initiate' || customer.userType == 'Fossil Migrate - Initiate') ? (
              <Tooltip placement="top" title="Retry SignUp">
                <IconButton
                  onClick={() => confirmRetryShufti(customer)}
                  style={{ marginTop: "-10px", marginBottom: "-10px" }}
                  aria-label="log">
                  <ReplayIcon />
                </IconButton>
              </Tooltip>

            ) : null}
            <Tooltip placement="top" title="Logs">
              <IconButton
                onClick={() => openLogModal(customer.userID, customer.userType)}
                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                aria-label="Shufti log">
                <TimelineIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
      switch (customer.statusShufti) {
        case 0:
          customer.updatedStatusShufti = (
            <Chip
              size="small"
              style={{ backgroundColor: "#6b6b6b", color: "white", textAlign: "center", width: "90px" }}
              label="Not Started"
            />
          );
          break;
        case 1:
          customer.updatedStatusShufti = (
            <Chip
              size="small"
              style={{ backgroundColor: "#1765bf", color: "white", textAlign: "center", width: "90px" }}
              label="Initiated"
            />
          );
          break;
        case 2:
          customer.updatedStatusShufti = (
            <Chip
              size="small"
              style={{ backgroundColor: "#cfbd00", color: "white", textAlign: "center", width: "90px" }}
              label="Done at App"
            />
          );
          break;
        case 3:
          customer.updatedStatusShufti = (
            <Chip
              size="small"
              style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px" }}
              label="Failed"
            />
          );
          break;
        case 4:
          customer.updatedStatusShufti = (
            <Chip
              size="small"
              style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }}
              label="Accepted"
            />
          );
          break;

        default:
          break;
      }
      if (customer.statusNetsys) {
        customer.convertedStatusNetsys = (
          <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
        );
      } else {
        customer.convertedStatusNetsys = (
          <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
        );
      }

      if (customer.statusIGA) {
        customer.convertedStatusIGA = (
          <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
        );
      } else {
        customer.convertedStatusIGA = (
          <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
        );
      }
      if (customer.statusUser) {
        customer.convertedStatusUser = (
          <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
        );
      } else {
        customer.convertedStatusUser = (
          <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
        );
      }
      // console.log(customer.CPR, (customer.statusNetsys && customer.statusCompliance))
      if (customer.statusNetsys && customer.statusCompliance) {
        customer.convertedComplianceStatus = (
          <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
        );
      } else {
        customer.convertedComplianceStatus = (
          <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="False" />
        );
      }
      // if (!customer.statusNetsys && !customer.statusUser) {
      //   customer.convertedComplianceStatus = <Chip size="small" style={{ backgroundColor: "#f05945", color: "white" }} label="Not Applicable" />
      // }
      return customer;
    });

    setCustomers(updatedData);
    setShowLoader(false);
    setShowCustomersTable(true);

    var updatedCsvData = data?.map((customer) => {
      let temp = {};
      temp.CPR = "'" + customer.cpr + "'";
      temp.User_ID = customer.userID;
      temp.User_Type = customer.userType;
      temp.Mobile = customer.mobile;
      temp.Device = customer.deviceID;
      temp.Language_Preferred = customer.languagePref;
      switch (customer.statusShufti) {
        case 0:
          temp.Shufti_Status = "Not Started";
          break;
        case 1:
          temp.Shufti_Status = "Initiated";
          break;
        case 2:
          temp.Shufti_Status = "Done at App";
          break;
        case 3:
          temp.Shufti_Status = "Failed";
          break;
        case 4:
          temp.Shufti_Status = "Accepted";
          break;

        default:
          break;
      }
      temp.IGA_Status = customer.statusIGA;
      temp.NetSys_Status = customer.statusNetsys;
      temp.User_Status = customer.statusUser;
      temp.AdditionalData_Status = customer.additionalDataStatus;
      return temp;
    });
    setCsvData(updatedCsvData);

  }

  const retryShufti = (userDetail) => {
    setRespAlert(
      <SweetAlert
        success
        style={{ display: "block" }}
        title="Retry initiated."
        onConfirm={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      />
    );
    let payload = {
      type: 'CPR',
      param: userDetail['cpr']
    }
    axios.post(`${config.url().appURL}/CXPortal/v4/user/register`, payload)
      .then((resp) => {
        console.log(resp['data'])
      }).catch((e) => {
        console.log(e)
      })
  }

  const confirmRetryShufti = (userDetail) => {
    setRespAlert(
      <SweetAlert
        warning
        style={{ display: "block" }}
        title="Are you sure?"
        onConfirm={() => {
          setRespAlert(null);
          retryShufti(userDetail)
        }}
        onCancel={() => {
          setRespAlert(null)
        }}
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Do you really want to retry shufti for this customer?
      </SweetAlert>)
  }


  const fetchUserDetails = (id) => {
    //function to fetch details of a customer
    setDetailsModal(true);
    setShowCustomersTable(false)
    setDetailsView(<CustomerDetails id={id} snack={openSnackBar} back={goBack} />)
    // axios.get(`${config.url().baseURL}/get-user/${id}`).then((resp) => {
    //   let respData = resp.data;
    //   if (!respData.err) {
    //     let customer = respData.data;
    //     if (customer) {
    //       if (customer.statusNetsys) {
    //         customer.NetSys_StatusRow = customer.statusNetsys
    //         loadAccountBalance(customer.cpr)
    //         customer.statusNetsys = (
    //           <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
    //         );
    //       } else {
    //         setAccountBalance("")
    //         setWalletNo("")
    //         setWalletType("")
    //         customer.statusNetsys = (
    //           <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />
    //         );
    //       }

    //       if (customer.statusIGA) {
    //         customer.statusIGA = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />;
    //       } else {
    //         customer.statusIGA = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />;
    //       }
    //       if (customer.statusUser) {
    //         customer.statusUser = <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />;
    //       } else {
    //         customer.statusUser = <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />;
    //       }
    //       if (customer.additionalDataStatus) {
    //         customer.additionalDataStatus = (
    //           <Chip size="small" style={{ backgroundColor: "green", color: "white" }} label="True" />
    //         );
    //       } else {
    //         customer.additionalDataStatus = (
    //           <Chip size="small" style={{ backgroundColor: "red", color: "white" }} label="False" />
    //         );
    //       }
    //       setUserDetails(customer);
    //     } else {
    //       setDetailsModal(false);
    //       openSnackBar("Customer data not found.");
    //     }

    //   } else {
    //     setDetailsModal(false);
    //     openSnackBar("Unable to process.");
    //   }
    // });
  };

  const goBack = () => {
    //function to go back
    setShowCustomersTable(true)
    setDetailsModal(false);
    setDetailsView(null)
    setShuftiModal(true);
    setShuftiView(null)
  }



  // const fetchShuftiData = async (ref) => {
  //   setShuftiModal(true);
  //   try {
  //     let respData = await axios.get(`${config.url().baseURL}/get-shufti/${ref}`)
  // let respData = await axios.get(`${config.url().baseURL}/get-shufti/ANDROID_SHUFTIPRO1638363362827094132925da97b65`)
  //     if (!respData.data.err) {
  //       setShuftiData(respData.data.data)
  //     } else {
  //       setShuftiData([]);
  //       setShuftiModal(false);
  //       openSnackBar(respData.data.msg);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     setShuftiData([]);
  //     setShuftiModal(false);
  //     openSnackBar("Unable fetch shufti data.");
  //   }
  // };


  const fetchShuftiData = async (shuftiRef) => {
    setShuftiModal(true);
    setShowCustomersTable(false)
    setShuftiView(<ShuftiDetails shuftiRef={shuftiRef} snack={openSnackBar} back={goBack} />)
  };

  const searchCustomer = () => {
    //function to search customer
    if (searchKey.length !== 0) {
      setSubmitting(true);
      axios.get(`${config.url().baseURL}/search-users/${searchKey}`).then((resp) => {
        let respData = resp.data;
        if (!respData.err) {
          setCustomerData(respData.data)
        } else {
          setCustomers([]);
          openSnackBar(respData.msg);
        }
        setSubmitting(false);
      })
        .catch((err) => {
          setSubmitting(false);
          setCustomers([]);
          fetchData()
          openSnackBar("Unable to process");

        });
    }
  }

  const resetSearch = () => {
    //function to reset search
    setSearchKey("")
    fetchData()
  }


  const loadAccountBalance = (cpr) => {
    //function to load account balance of a customer 
    axios.get(`${config.url().baseURL}/get-wallet-balance?id=${cpr}`)
      .then((resp) => {
        if (!resp.data.err) {
          setAccountBalance(resp.data.data[0].walletBalance)
          setWalletType(resp.data.data[0].walletType)
          setWalletNo(resp.data.data[0].walletAccNumber)
          setEmployerName(resp.data.data[0].employerName)
          setUserName(resp.data.data[0].name)
          setCustomerDOB(resp.data.data[0].DOB)
          setCustomerMobile(resp.data.data[0].mobile)
        } else {
          openSnackBar("Unable to load account balance.")
        }
      }).catch((e) => {
        console.log(e)
      })
  }

  const downloadAllCustomers = () => {
    try {
      axios.post(`${config.url().baseURL}/customer-infos`, { responseType: "blob", toDate, fromDate }).then(async (resp) => {
        if (!resp.data.err) download(resp.data, "Customers.csv");
        else openSnackBar(resp.data.msg)
      }).catch((err) => {
        console.log(err);
        openSnackBar("Unable tp process.");
      });
    } catch (error) {
      openSnackBar("Unable to process.")
    }
  }

  const unlockUser = (id) => {
    //function to unlock user
    try {
      axios.post(`${config.url().baseURL}/unlock-user/${id}`)
        .then(async (resp) => {
          if (!resp.data.err) {
            setRespAlert(
              <SweetAlert
                success
                style={{ display: "block" }}
                title="User unlocked."
                onConfirm={() => { hideAlert(); fetchUserDetails(id) }}
                confirmBtnCssClass={classes.button + " " + classes.success}
              />
            )
          } else openSnackBar("Unable tp process.");
        })
        .catch((err) => {
          console.log(err);
          openSnackBar("Unable tp process.");
        });
    } catch (error) {
      openSnackBar("Unable to process.")
    }
  }

  const openFailedLog = () => {
    //function to open failed log modal
    setShowFailedLogs(true)
    setFailedLogs(<FailedLog />)
  }

  const cancelDownload = () => {
    //function to cancel download excel
    setToDate(moment(new Date).format("YYYY-MM-DD"))
    setFromDate(moment(new Date).format("YYYY-MM-DD"))
    setShowDownload(false)
  }

  const openEditEmployee = (emp) => {
    //function open employee details edit
    setShowEdit(true)
    setEditForm(<EditForm emp={emp} close={closeEdit} />)
  }

  const closeEdit = () => {
    //function to close edit form modal
    setEditForm(null)
    setShowEdit(false)
  }

  const downloadFailedCustomers = () => {
    //function to download all customer csv 
    try {
      axios.get(`${config.url().baseURL}/getBlockedUsers`, { responseType: "blob" }).then(async (resp) => {
        if (!resp.data.err) download(resp.data, "FailedUsers.csv");
        else openSnackBar(resp.data.msg)
      }).catch((err) => {
        console.log(err);
        openSnackBar("Unable tp process.");
      });
    } catch (error) {
      openSnackBar("Unable to process.")
    }
  }

  const auditModelLog = (userID, mpageno) => {

    setShowAuditLogModel(true);
    let data = {
      userId: userID,
      pageno: mpageno
    }
    setMRemainingDocs(false)

    try {
      axios.post(`${config.url().baseURL}/get-audit-logs`, data).then((resp) => {
        let respData = resp.data;
        if (!respData?.err) {
          if (respData.data !== undefined) {
            setAuditLog(respData.data.logs);
            if (respData.data.remainingRecords == 0) {
              setMRemainingDocs(true)
            }
            setUserId(data.userId);
          } else {
            setAuditLogModel(false);
            openSnackBar("No AUditLog");
            setMRemainingDocs(true)


          }

        } else {
          setShowAuditLogModel(false);
          openSnackBar(respData.msg);
          setMRemainingDocs(true)

        }
      });
    } catch (error) {
      setMRemainingDocs(true)

    }

  };

  const mNextTransaction = () => {
    setMPageValue(mpageValue => mpageValue + 1)
    auditModelLog(userId, mpageValue + 1)
  }
  const MPreviousTransaction = () => {
    setMPageValue(mpageValue => mpageValue - 1)
    auditModelLog(userId, mpageValue - 1)
  }


  return (
    <div>
      {respAlert}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showLoader}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Collapse
            in={showCustomersTable}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Collapse
                  in={!showDownload}
                  style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                  {...(true ? { timeout: 500 } : {})}>

                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                      <Paper elevation={1} className={classes.addDeptContainer}>
                        <InputBase
                          id="cusSearch"
                          autoFocus={true}
                          fullWidth
                          type="search"
                          className={classes.input}
                          placeholder="Search"
                          inputProps={{

                            "aria-label": "Search",
                            onChange: (event) => { setSearchKey(event.target.value) },
                            onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                            value: searchKey,
                            onKeyDown: (e) => {
                              if (e.key == "Enter") {
                                searchCustomer()

                              }
                            }
                          }}
                        />
                        {!submitting ? (
                          <Button submit className={classes.addDeptButton}
                            onClick={() => searchCustomer()}
                            size="lg" color="primary">
                            <SearchIcon fontSize="medium" />
                          </Button>
                        ) : (
                          <BounceLoader color="#004fa4" loading={true} css={override} size={50} />
                        )}
                      </Paper>
                      <Tooltip placement="bottom" title="Reset">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => resetSearch()}
                          size="lg" color="primary">
                          <RotateLeftIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="bottom" title="Download CSV">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => setShowDownload(true)}
                          size="lg" color="primary">
                          <GetAppIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="bottom" title="Failed User Log">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => openFailedLog()}
                          size="lg" color="primary">
                          <PersonAddDisabledIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="bottom" title="Download Failed users List">
                        <IconButton className={classes.addDeptButton}
                          onClick={() => downloadFailedCustomers()}
                          size="lg" color="primary">
                          <SystemUpdateAltIcon fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </GridItem>
                  </GridContainer>
                </Collapse>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Collapse
                  in={showDownload}
                  style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                  {...(true ? { timeout: 500 } : {})}>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={3}>
                      <p className={classes.searchHead}>From Date</p>
                      <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                        <InputBase
                          autoFocus={true}
                          fullWidth
                          className={classes.input}
                          inputProps={{
                            type: "date",
                            max: moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD'),
                            onChange: (event) => {
                              setFromDate(event.target.value)
                              if (moment(moment(event.target.value, "YYYY-MM-DD").add(30, 'days')).isBefore(moment(new Date()))) {
                                setMaxToDate(moment(event.target.value, "YYYY-MM-DD").add(30, 'days').format('YYYY-MM-DD'))
                                setToDate(moment(event.target.value, "YYYY-MM-DD").add(30, 'days').format('YYYY-MM-DD'))
                              } else {
                                setMaxToDate(moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD'))
                                setToDate(moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD'))
                              }
                            },
                            value: fromDate,
                            onBlur: (e) => {
                              if (moment(e.target.value).isAfter(moment(new Date()))) {
                                setFromDate(moment(new Date).format("YYYY-MM-DD"))
                                openSnackBar("Please enter valid date.")
                              }
                            }
                          }}
                        />
                      </Paper>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <p className={classes.searchHead}>To Date</p>
                      <Paper component="form" elevation={1} className={classes.addDeptContainer}>
                        <InputBase
                          autoFocus={true}
                          fullWidth
                          className={classes.input}
                          inputProps={{
                            type: "date",
                            max: maxToDate,
                            onChange: (event) => { setToDate(event.target.value) },
                            value: toDate,
                          }}
                        />
                      </Paper>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ textAlign: "center" }}>
                        <Button simple color="primary" onClick={() => cancelDownload()} > Cancel</Button> <Button color="primary" onClick={downloadAllCustomers} > Download</Button>
                      </div>
                    </GridItem>
                  </GridContainer>
                </Collapse>
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardBody>
                    <MUIDataTable data={customers} columns={columns} options={options} />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </Collapse>
          <Collapse in={detailsModal} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
            {detailsView}
          </Collapse>
          <Collapse in={shuftiModal}
            style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 100 } : {})}
          >
            {shuftiView}
          </Collapse>
        </GridItem>
      </GridContainer>
      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
        open={showAuditLogModel}
        // TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          closeTransactionModal()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
          <TableContainer className={classes.container}>
            <MuiTable stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {auditLogColumn?.map((column, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: column.width }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {auditLog?.map((row, key) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                      <TableCell>
                        {moment(row.date).format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                      <TableCell>
                        {row.field}
                      </TableCell>
                      <TableCell>
                        {row.user}
                      </TableCell>
                      <TableCell>
                        {row.userType}
                      </TableCell>
                      <TableCell>
                        <Tooltip placement="top" title="View">
                          <IconButton
                            onClick={() => openAuditLogModal(userId, row._id)}
                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                            aria-label="view"
                          >
                            <TimelineIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </MuiTable>
          </TableContainer>
          {auditLogModel}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            disabled={mpageValue <= 0 ? true : false}
            onClick={() => MPreviousTransaction()}
            color="primary"
            size="sm">
            Previous
          </Button>
          <Button
            disabled={mremainingDocs}
            onClick={() => mNextTransaction()}
            color="primary"
            size="sm">
            Next
          </Button>

          <Button
            onClick={() => {
              closeAuditLogModal()
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.companyLogModal, }}
        open={showLogModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          closeLogModal()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {logModel}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              closeLogModal()
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
        open={showTransactionsModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          closeTransactionModal()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {transactionModel}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <Button
            onClick={() => {
              closeTransactionModal()
            }}
            color="danger"
            size="sm">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.transactionsModal, }}
        open={showScreeningModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setShowScreeningModel(false);
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {screeningModel}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.shuftiModal, }}
        open={showFailedLogs}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setFailedLogs(null);
          setShowFailedLogs(false)
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {failedLogs}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
          <DialogActions className={classes.modalFooterCenter}>
            <Button
              onClick={() => {
                setFailedLogs(null);
                setShowFailedLogs(false)
              }}
              color="danger"
              size="sm">
              Close
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog classes={{ root: classes.left + " " + classes.modalRoot, paper: classes.shuftiModal }}
        open={showEdit}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          closeEdit()
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description">
        <DialogContent id="classic-modal-slide-description" className={classes.logModalBody}>
          {editForm}
        </DialogContent>
        <DialogActions className={classes.modalFooterCenter}>
        </DialogActions>
      </Dialog>

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnack}
        autoHideDuration={6000}>
        <Alert
          onClose={() => {
            setOpenSnack(false);
            setSnackBarMsg("");
          }}
          severity="error">
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
