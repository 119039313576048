import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";

const useStyles = makeStyles({
  container: {
    maxHeight: 440,
  },
  tableHead: {
    backgroundColor: "#fffff",
  },
  tableBodyCell: {
    backgroundColor: "#fffff",
  },
});

const CustomTable = (props) => {
  const classes = useStyles();
  const {
    columns,
    data,
    page,
    rowsPerPage,
    totalRows,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  return (
    <div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow className={classes.tableHead}>
              {columns.map((column, index) => (
                <TableCell key={index} style={{ minWidth: column.width }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {columns.map((column, colIndex) => {
                  if (column.key === "actions") {
                    return (
                      <TableCell key={colIndex} className={classes.tableBodyCell}>
                        {row.actions} {/* Render the actions directly */}
                      </TableCell>
                    );
                  } else if (column.key === "date") {
                    return (
                      <TableCell key={colIndex} className={classes.tableBodyCell}>
                        {moment(row[column.key]).format("DD/MM/YYYY HH:mm:ss")}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell key={colIndex} className={classes.tableBodyCell}>
                        {row[column.key]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </div>
  );
};

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      width: PropTypes.number,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  totalRows: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default CustomTable;




// import React from 'react';
// import MUIDataTable from 'mui-datatables';

// const CustomTable = ({ columns, data, page, rowsPerPage, totalRows, onChangePage, onChangeRowsPerPage }) => {
//     const options = {
//         selectableRows: false,
//         elevation: 0,
//         print: false,
//         serverSide: true,
//         count: totalRows,
//         rowsPerPage: rowsPerPage,
//         page: page,
//         onTableChange: (action, tableState) => {
//             switch (action) {
//                 case 'changePage':
//                     onChangePage(null, tableState.page);
//                     break;
//                 case 'changeRowsPerPage':
//                     onChangeRowsPerPage({
//                         target: {
//                             value: tableState.rowsPerPage,
//                         },
//                     });
//                     break;
//                 default:
//                     break;
//             }
//         },
//     };

//     return <MUIDataTable title="Corporate Portal Data" data={data} columns={columns} options={options} />;
// };

// export default CustomTable;
