import React from 'react';
import {
    Modal,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    Stack,
} from '@mui/material';
import { format } from 'date-fns';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    outline: 'none',
};

const LogsModal = ({ open, onClose, logs }) => {
    return (
        <Modal open={open} onClose={onClose} aria-labelledby="logs-modal-title">
            <Box sx={modalStyle}>
                <Typography variant="h6" id="logs-modal-title" gutterBottom>
                    Ekyc Report Log
                </Typography>

                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>Date</strong></TableCell>
                                <TableCell><strong>Time</strong></TableCell>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell><strong>Status</strong></TableCell>
                                <TableCell><strong>User Email</strong></TableCell>
                                <TableCell><strong>Remarks</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.length > 0 ? (
                                logs.map((log) => (
                                    <TableRow key={log._id}>
                                        <TableCell>{format(new Date(log.time), 'dd/MM/yyyy')}</TableCell>
                                        <TableCell>{format(new Date(log.time), 'HH:mm:ss')}</TableCell>
                                        <TableCell>{log.name || 'N/A'}</TableCell>
                                        <TableCell>{log.status=="REJECTED"?"RE-DO":log.status}</TableCell>
                                        <TableCell>{log.email || 'N/A'}</TableCell>
                                        <TableCell>{log.remarks}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No logs available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack direction="row" justifyContent="center" sx={{ mt: 2 }}>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClose}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 2,
                            paddingX: 4,
                            paddingY: 1,
                            fontWeight: 'bold',
                        }}
                    >
                        Close
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default LogsModal;
