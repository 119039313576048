

// Function to fetch data for Table 1 (dummyData1)
// src/utils/sweetAlert.js
import Swal from 'sweetalert2';

export const showAlert = (type, title, text) => {
    Swal.fire({
        icon: type,
        title: title,
        text: text,
        confirmButtonText: 'OK',
    });
};

export const showConfirmation = async (title, text) => {
    const result = await Swal.fire({
        icon: 'question',
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    });

    return result.isConfirmed;
};

export const customerDetailsColumns = [
    { id: 'createdAt', label: 'Date of KYC', minWidth: 120, align: 'left' },
    { id: 'cpr', label: 'CPR', minWidth: 100, align: 'left' },
    { id: 'referenceNumber', label: 'Shufti Reference', minWidth: 150, align: 'left' },
    { id: 'walletType', label: 'User Type', minWidth: 120, align: 'left' },
    { id: 'reasons', label: 'Reasons', minWidth: 120, align: 'left' },
    { id: 'action', label: 'Status', minWidth: 120, align: 'left' },
    { id: 'verificationType', label: 'eKYC Type', minWidth: 120, align: 'left' },
    { id: 'actions', label: 'Actions', minWidth: 200, align: 'left' },
];

// export const rejectCustomerDetailsColumns = [
//     { id: 'createdAt', label: 'Date of KYC', minWidth: 120, align: 'left' },
//     { id: 'cpr', label: 'CPR', minWidth: 100, align: 'left' },
//     { id: 'referenceNumber', label: 'Shufti Reference', minWidth: 150, align: 'left' },
//     { id: 'walletType', label: 'User Type', minWidth: 120, align: 'left' },
//     { id: 'reasons', label: 'Reasons', minWidth: 120, align: 'left' },
//     { id: 'action', label: 'Status', minWidth: 120, align: 'left' },
//     { id: 'declineReason', label: 'Decline Reason', minWidth: 200, align: 'left' },
// ];