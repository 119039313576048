import React, { useState, useEffect } from 'react';
import CustomTable from '../../components/Table/customTable';
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import SweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Header from './component/Header';
import Collapse from '@material-ui/core/Collapse';
import ViewCorpLoadRequest from './component/ViewCorpLoadRequest'
import ViewCorpLoadRequestLog from './component/viewLog';
import axios from 'axios';
import moment from 'moment';
import { Card, IconButton } from '@material-ui/core';
import config from '../../config'
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import { Visibility } from '@material-ui/icons';
import SnackBar from '../../components/Snackbar/Snackbar'
import { Info, Error, Favorite } from '@material-ui/icons';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => ({
    ...SweetAlertStyle,
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        float: "none",  // Ensure it doesn't float
        display: "block",  // Ensure it takes up the entire block
        width: "100%",  // Ensure it takes up full width
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    addButton: {
        float: "right !important",
        display: "inlineBlock",
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    addDeptContainer: {
        height: "49px",
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        marginBottom: "5px !important",
    },
    addDeptButton: {
        marginRight: "-5px !important",
    },
    input: {
        flex: 1,
        height: "45px",
        marginLeft: "15px",
    },
    searchHead: {
        marginTop: "-5px",
        marginBottom: "1px",
        color: "#144b85",
        fontWeight: "400"
    },
    companyStatusLogModal12: {
        margin: "auto",
        minWidth: "800px",
    },
    dialogContainer: {
        display: "flex",
    },
    inputPassword: {
        flex: 1,
        marginTop: "10px",
        height: "45px",

        paddingLeft: "10px",
    },
    nextButton: {
        marginLeft: "150px"
    },
    passwordHead: {
        fontWeightL: "500",
        marginBottom: "0px",
        color: "#ffffff"
    },
    indicator: {
        backgroundColor: 'white',
    },

    ...modalStyle(theme),
}));


const CorporatePortal = () => {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalRows, setTotalRows] = useState(0);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [status, setStatus] = useState("");
    const [crNumber, setCrNumber] = useState("");
    const [ViewListCorpLoad, setViewListAllCorpLoad] = useState(false);
    const [showLoader, setShowLoader] = useState(true)
    const [showCorpView, setShowCorpView] = useState(false)
    const [corpDetailView, setCorpDetailView] = useState({})
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackColor, setSnackColor] = useState("");
    const [snackIcon, setSnackIcon] = useState();
    const [showLog, setShowLog] = useState(false);
    const [transactionID, setTransactionID] = useState("");



    const options = {
        selectableRows: false,
        elevation: 0,
        print: false,
        // onDownload: () => {
        //   let csvFile = creatCsvFile(csvData);
        //   let fileName = "customers.csv";
        //   downloadFile(csvFile, fileName);
        //   return false;
        // },
        textLabels: {
            body: {
                noMatch: "No matching records found",
            },
        }
    };

    const handleBack = () => {
        setViewListAllCorpLoad(true);
        setShowCorpView(false)
        setSnackbarOpen(false)
        setShowLog(false)
        const payload = {
            pageNumber: page + 1,
            pageSize: rowsPerPage
        }
        fetchData(payload)
        // setCorpDetailView([])
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const fetchData = async (payload) => {
        try {
            const response = await axios.post(`${config.url().baseURL}/get-corporate-load-requests`, {
                pageNo: payload.pageNumber ? payload.pageNumber : page + 1,
                pageSize: payload.pageSize ? payload.pageSize : rowsPerPage,
                CRNo: payload.crNumber,
                fromDate: payload.fromDate,
                toDate: payload.toDate,
                status: payload.status

            });
            if (response?.data?.err) {
            } else {
                setData(response?.data?.data);
                setTotalRows(response.data.totalCount);
                setViewListAllCorpLoad(true)
                setShowLoader(false)
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const payload = {
            pageNumber: page + 1,
            pageSize: rowsPerPage
        }
        fetchData(payload); // API usually expects 1-based page index
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeFilter = (event, type) => {
        switch (type) {
            case "fromDate":
                setFromDate(event.target.value)
                break;
            case "toDate":
                setToDate(event.target.value)
                break;
            case "status":
                setStatus(event.target.value)
                break;
            case "crNumber":
                setCrNumber(event.target.value)
                break;
            case "reset":
                setCrNumber("")
                setFromDate("")
                setToDate("")
                setStatus("")
                console.log("reset case")
                break;
        }
    }

    const handleViewClick = async (transactionID, crNumber) => {
        try {
            setViewListAllCorpLoad(false)
            setShowLoader(true)
            let response = await axios.post(`${config.url().baseURL}/get-corporate-load-request-details`, {
                CRNo: crNumber,
                transactionID

            });
            setShowLoader(false)

            if (response.data.err) {
                setSnackbarOpen(true)
                setSnackMessage(response.data.msg)
                setSnackIcon(Error)
                setSnackColor("danger")
            } else {
                response = response.data.data
                response.CRNo = crNumber
                console.log(response)
                setCorpDetailView(response)
                setShowCorpView(true)

            }

        } catch (err) {
            console.log(err)
        }
    };

    const handleViewLog = (transactionID) => {
        // Log the edit action here
        setTransactionID(transactionID)
        setShowLog(true)
        // Add your edit logic here (if needed)
        // For example, open an edit form or navigate to an edit page
    };

    const columns = [
        {
            label: 'Date',
            width: 100,
            key: 'dateRequested',
            // render: (row) => (moment.utc(row.dateRequested).local().format("DD/MM/YYYY")),
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'External TransactionID',
            width: 100,
            key: 'externalTransactionID',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'Amount',
            width: 150,
            key: 'amount',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'Transaction Type',
            width: 100,
            key: 'transactionType',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'status',
            width: 50,
            key: 'status',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'CR No',
            width: 50,
            key: 'CRNo',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'Corporate Name',
            width: 150,
            key: 'CorporateName',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },

        {
            label: 'CX Status',
            width: 50,
            key: 'cxStatus',
            options: {
                filter: true, // Disable filtering for this column
                sort: true,    // Enable sorting for this column
            }
        },
        {
            label: 'Actions',
            width: 0,
            key: 'actions',
            render: (row) => (
                <div>
                    <IconButton
                        // onClick={() => handleViewClick(row)}
                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                        aria-label="edit"
                    >mcCorporateLoad
                        <EditIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const mappedData = data?.map((item) => ({
        
        ...item,
        dateRequested:moment.utc(item.dateRequested).local().format("DD/MM/YYYY h:mm:ss A"),
        actions: (
            <div>
                <IconButton
                    onClick={() => {
                        handleViewClick(item.transactionID, item.CRNo)

                    }}
                    style={{
                        marginTop: "-10px",
                        marginBottom: "-10px",
                    }}
                    aria-label="view"
                >
                    <Visibility />
                </IconButton>

                <IconButton
                    onClick={() => {
                        handleViewLog(item.transactionID)

                    }}
                    style={{
                        marginTop: "-10px",
                        marginBottom: "-10px",
                    }}
                    aria-label="view log"
                >
                    <TimelineIcon />
                </IconButton>
                {/* <button onClick={() => handleViewClick(item._id)}>View</button>
        <button onClick={() => handleEditClick(item._id)}>Edit</button> */}
            </div>
        ),
    }));

    return (
        <div className="corporate-portal">
            <Collapse in={showCorpView}>
                <ViewCorpLoadRequest
                    back={handleBack}
                    transaction={corpDetailView}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackColor={setSnackColor}
                    setSnackMessage={setSnackMessage}
                    setSnackIcon={setSnackIcon}
                />
            </Collapse>

            <Collapse in={ViewListCorpLoad} timeout="auto" unmountOnExit>

                <div className={classes.headerContainer}>
                    <Header
                        onChangeFilter={handleChangeFilter}
                        fromDate={fromDate}
                        toDate={toDate}
                        status={status}
                        crNumber={crNumber}
                        filterCorprateLoad={fetchData}


                    />
                </div>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Corprate wallet load</h4>

                    </CardHeader>
                    <CardBody>
                        <CustomTable
                            columns={columns}
                            data={mappedData}
                            page={page}
                            options={options}
                            rowsPerPage={rowsPerPage}
                            totalRows={totalRows}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </CardBody>
                </Card>
            </Collapse>


            <Collapse in={showLog} timeout="auto" unmountOnExit>
                <ViewCorpLoadRequestLog
                    id={transactionID}
                    back={handleBack}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackColor={setSnackColor}
                    setSnackMessage={setSnackMessage}
                    setSnackIcon={setSnackIcon}

                />
            </Collapse>

            <SnackBar
                open={snackbarOpen}
                message={snackMessage}
                color={snackColor}
                place="br"
                icon={snackIcon}
                close={true}
                closeNotification={handleCloseSnackbar}
            />
        </div>
    );
};

export default CorporatePortal;

