import React, { useEffect, useState } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Collapse from "@material-ui/core/Collapse";
import PropagateLoader from "react-spinners/PropagateLoader";
import { css } from "@emotion/core";
import { useSelector } from 'react-redux';
import SnackBar from '../../../components/Snackbar/Snackbar'
import axios from "axios";
import { Info, Error, Favorite } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';

import config from '../../../config'

import { FormControl, InputLabel, MenuItem, Modal, Select, TextField } from "@material-ui/core";
import moment from "moment";
import sweetAlertStyle from "assets/jss/material-dashboard-react/views/sweetAlertStyle";
import customSelectStyle from "assets/jss/material-dashboard-react/customSelectStyle";
import modalStyle from "assets/jss/material-dashboard-react/modalStyle";

// const override = css`
//   display: block;
//   margin: 32px auto;
//   border-color: red;
// `;

const styles = (theme) => ({
  ...sweetAlertStyle,
  ...customSelectStyle,
  subHead: {
    fontWeight: "500",
    marginBottom: "-2px !important",
    float: "left",
    display: "inlineBlock",
  },
  docName: {
    fontSize: "14px !important",
    fontWeight: "500",
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  uploadButton: {
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
  required: {
    color: "red",
  },
  sideHead: {
    fontWeight: "500",
    fontSize: "14px",
    marginBottom: "0px !important",
    marginTop: "25px !important",
    color: "#474747",
  },
  addActions: {
    float: "right",
    display: "inlineBlock",
  },
  question: {
    fontSize: "18px",
    fontWeight: "400",
  },
  radioGroup: {
    marginTop: "-20px !important",
  },
  radioGroupFee: {
    height: "25px !important",
    overFlow: "hidden"
  },
  dateLabel: {
    marginTop: "-18px",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "12px",
    fontWeight: "400",
  },
  dateInput: {
    marginTop: "20px !important",
  },
  addOwnerActions: {
    float: "right",
    display: "inlineBlock",
  },
  backButton: {
    float: "left !important",
    display: "inlineBlock",
    marginTop: "0 !important",
    marginBottom: "0 !important",
  },
  ...modalStyle(theme),
});

const override = css`
  display: block;
  margin: -10px;
  border-color: red;
`;
const useStyles = makeStyles(styles);

export default function TransactionForm(props) {
  const [dateRequested, setDateRequested] = useState("");
  const [externalTransactionID, setExternalTransactionID] = useState("");
  const [amount, setAmount] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [transactionFileUrl, setTransactionFileUrl] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [comment, setComment] = useState("");
  const [values, setValues] = useState({ amountErr: null });
  const [formView, setFormView] = useState(1); // 0:-loader , 1:-transaction form
  const [submitting, setSubmitting] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(state => state.user);

  console.log(user)
  const classes = useStyles();


  useEffect(() => {
    if (props.transaction) {
      setDateRequested(moment.utc(props.transaction?.dateRequested).local().format("DD/MM/YYYY h:mm:ss A") || "");
      setExternalTransactionID(props.transaction.externalTransactionID || "");
      setAmount(props.transaction.amount || "");
      setTransactionType(props.transaction.transactionType || "");
      setTransactionFileUrl(props.transaction.transactionFileUrl || "");
      setDescription(props.transaction.description || "");
      setStatus(props.transaction.status || "");
      setTransactionID(props.transaction.transactionID || "");
      setComment(props.transaction.remarks || "");
      setRemarks("")
    }
  }, [props.transaction]);
  let service = user && user.services && user.services.find(service => service.name == 'MC Corporate Load')
  let isDisabled = ((service.type === "maker") && (props.transaction.cxStatus === "UPDATED" || props.transaction.cxStatus === "APPROVED") || (service.type === "checker") && (props.transaction.cxStatus !== "UPDATED"));

  const validateForm = () => {
    // function to validate form
    let amountErr = false, flag = true, msg = "", commentErr = false;
    if (amount.length === 0) {
      flag = false;
      amountErr = true;
      msg = "Please enter amount.";
    }
    if (!status) {
      msg = "Please Select one of the Status"
      flag = false
    }
    if (!comment) {
      msg = "Please type Comment"
      flag = false;
      commentErr = true

    }
    if (!flag) {
      props.setSnackbarOpen(true)
      props.setSnackMessage(msg)
      props.setSnackIcon(Error)
      props.setSnackColor("danger")
    }
    setValues({ ...values, amountErr });
    return flag;
  };

  const updateCorprateLoadRequest = async (cxStatus) => {
    try {
      if (cxStatus !== "CANCEL") {
        let payload = {
          cxStatus,
          CRNo: props.transaction.CRNo,
          status,
          transactionID: transactionID,
          remarks: comment,
          comments: remarks
        };
        const resp = await axios.post(`${config.url().baseURL}/update-corporate-load-request`, payload)
        console.log(resp)
        if (resp.data.err) {
          props.setSnackbarOpen(true)
          props.setSnackMessage(resp.data.msg)
          props.setSnackIcon(Error)
          props.setSnackColor("danger")

          
        } else {
          Swal.fire({
            title: 'Corporate Load request Updated Successfully',
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'your-custom-class', // Add your classes here
            },
          }).then(() => {
            // Perform any action after the alert is confirmed
            props.back();
          });
        }
      }
      setSubmitting(false)
      setShowModal(false)

    } catch (err) {
      console.log(err)
    }
  }

  const submitData = async () => {
    // function to submit data
    setSubmitting(true);
    if (!validateForm()) setSubmitting(false);
    else {
      setShowModal(true)
    }
  };

  return (
    <div>
      <GridContainer justify="center">

        <Modal open={showModal} onClose={() => { setShowModal(false); setSubmitting(false) }}>
          <Card style={{ width: 1000, margin: '0 auto', marginTop: 100, boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40 }}>
              <h2 style={{ textAlign: 'center', marginBottom: 30, fontSize: 24 }}>Approve</h2>
              <div style={{ flex: 1 }}>
                <TextField
                  label="Remark"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setRemarks(e.target.value)}
                  value={remarks}
                  required  // Add the required attribute here
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <Button variant="contained" color="primary" onClick={() => {
                  const cxStatus = service.type == 'maker' ? "UPDATED" : "APPROVED"
                  updateCorprateLoadRequest(cxStatus)

                }}>  {service?.type == 'maker' ? `Update` : `Approve`}</Button>
                <Button variant="contained" color="danger" onClick={() => {
                  const cxStatus = service.type == 'checker' ? "RECHECKED" : "CANCEL"
                  updateCorprateLoadRequest(cxStatus)
                }}>
                  {service?.type == 'maker' ? `Cancel` : `Recheck`}</Button>


              </div>
            </CardBody>
          </Card>
        </Modal>
        <GridItem xs={12} sm={12} md={8}>
          <Collapse
            in={formView === 0}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
          </Collapse>
          <Collapse
            in={formView === 1}
            style={{ transformOrigin: "0 0 0", textAlign: "center" }}
            {...(true ? { timeout: 500 } : {})}>
            <Card profile>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Date Requested"
                      id="date_requested"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: dateRequested,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="External Transaction ID"
                      id="external_transaction_id"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: externalTransactionID,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Amount"
                      id="amount"
                      error={values.amountErr}
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: amount,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Transaction Type"
                      id="transaction_type"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: transactionType,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Description"
                      id="description"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        multiline: true,
                        maxRows: 5,
                        value: description,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="Transaction File URL"
                      id="transaction_file_url"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: "",
                        endAdornment: (
                          <Button
                            color="primary"
                            onClick={() => window.open(transactionFileUrl, "_blank")}
                          >
                            Download
                          </Button>
                        )
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                      <InputLabel id="status-label">
                        <span>
                          Status <small className={classes.required}>*</small>
                        </span>
                      </InputLabel>
                      <Select
                        labelId="status-label"
                        id="status"
                        value={status}
                        disabled={isDisabled || service.type === 'checker'}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <MenuItem value="REQUESTED">REQUESTED</MenuItem>
                        <MenuItem value="SUCCESSFUL">SUCCESSFUL</MenuItem>
                        <MenuItem value="INPROGRESS">INPROGRESS</MenuItem>
                        <MenuItem value="FAILED">FAILED</MenuItem>
                        <MenuItem value="DECLINED">DECLINED</MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Transaction ID"
                      id="transaction_id"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        readOnly: true,
                        value: transactionID,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={
                        <span>
                          Comment <small className={classes.required}>*</small>
                        </span>
                      }
                      id="comment"
                      error={values.amountErr}

                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        value: comment,
                        readOnly: isDisabled || service.type === 'checker',
                        onChange: (e) => setComment(e.target.value),
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>

              {
                isDisabled ?
                  <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button simple onClick={() => props.back()} color="primary">Back</Button>
                  </div> :
                  <div style={{ textAlign: "center", marginBottom: "20px" }}>
                    <Button simple onClick={() => props.back()} color="primary">Back</Button>
                    <Button disabled={submitting} onClick={submitData} color="primary">submit</Button>
                  </div>
              }
            </Card>

          </Collapse>
        </GridItem>
      </GridContainer>
    </div>
  );
}
