import React, { useState } from "react";
import {
    Paper,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Collapse,
    Typography
} from "@material-ui/core";
import PropagateLoader from "react-spinners/PropagateLoader";

import { ExpandMore as ExpandMoreIcon, FileCopy as FileCopyIcon } from "@material-ui/icons";
import moment from "moment";
import JSONPretty from "react-json-pretty";

const LogsCollapse = ({ logs = [], showLoadMore, getLogs, userID, pageNo, classes }) => {
    const copyToClipboardNew = (log) => {
        // Implement the copy logic here
        navigator.clipboard.writeText(JSON.stringify(log, null, 2));
    };

    return (

            <Collapse
                in={logs.length !== 0}
                style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                timeout={500}
            >




                <div className={classes.logContainer}>
                    {logs.map((log, index) => (
                        <React.Fragment key={index}>
                            <Paper elevation={3} className={classes.logPaper}>
                                {log._id && (
                                    <>
                                        Log ID: {log._id}
                                        <IconButton
                                            style={{ transformOrigin: "0 0 0", float: "right" }}
                                            onClick={() => copyToClipboardNew(log)}
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                        <br />
                                    </>
                                )}
                                {log.name && <>Log Type: {log.name}<br /></>}
                                {log.createdAt && (
                                    <>
                                        Date: {moment(log.createdAt).format("DD/MM/YYYY")}<br />
                                        Time: {moment(log.createdAt).format("hh:mm:ss A")}<br />
                                    </>
                                )}
                                {log.url && <>URL: {log.url}<br /></>}
                                {log.timeRequired && <>Duration: {log.timeRequired}<br /></>}
                                {log.response && (
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="response-content">
                                            <Typography>Response</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ width: "750px" }}>
                                                <JSONPretty data={log.response} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                                {log.request && (
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="request-content">
                                            <Typography>Request</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ width: "750px" }}>
                                                <JSONPretty data={log.request} />
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Paper>
                            <br />
                        </React.Fragment>
                    ))}
                    {showLoadMore && (
                        <Button fullWidth onClick={() => getLogs(userID, pageNo)}>
                            Load More
                        </Button>
                    )}
                </div>
            </Collapse>
    );
};

export default LogsCollapse;
