export default (state = 0, action) => {
  switch (action.type) {
    case "companyID":
      return {
        ...state,
        companyID: action.payload,
      };
    case "companyInfo":
      return {
        ...state,
        companyInfo: action.payload,
      };
    case "ownersAndCtrls":
      return {
        ...state,
        ownersAndCtrls: action.payload,
      };
    case "businessProfile":
      return {
        ...state,
        businessProfile: action.payload,
      };

    case "editData":
      return {
        ...state,
        editData: action.payload,
      };
      case "m2pWalletCreate":
        return {
          ...state,
          businessProfile: { ...state.businessProfile, m2pWalletCreate: action.payload },
        };
    default:
      return state;
  }
};
