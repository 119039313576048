import React from 'react';
import axios from "axios";
import config from "config.js"
import moment from "moment";
import libs from "controllers/libs";
import fetch from 'node-fetch';
import PropagateLoader from "react-spinners/PropagateLoader";
import Chip from "@material-ui/core/Chip";
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import MuiAlert from "@material-ui/lab/Alert";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SweetAlert from "react-bootstrap-sweetalert";
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from "@material-ui/icons/Visibility";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import NameScreen from 'views/Customers/Components/NameScreen.js';
import CustomerDetails from "views/Customers/Components/CustomerDetails.js"
import ShuftiDetails from "views/Customers/Components/ShuftiDetails.js"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ShuftiDetailsV2 from './components/shuftiView';
import styles from "assets/jss/material-dashboard-react/views/customersStyle.js";
import ArchiveIcon from '@mui/icons-material/Archive';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useSelector } from 'react-redux';
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import BounceLoader from "react-spinners/BounceLoader";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import MUIDataTable from "mui-datatables";
import LocationOnIcon from '@mui/icons-material/LocationOn';



const useStyles = makeStyles(styles);

const override = css`
display: block;
margin: 0px;
border-color: red;
`;

const columns = [
    {
        field: "date",
        title: "Date",
        render: rowData => moment(rowData.updatedAt).format("DD/MM/YYYY") != "Invalid date" ? moment(rowData.createdAt).format("DD/MM/YYYY") : "",
    },
    {
        field: "cpr",
        title: "CPR",
    },
    {
        field: "mobile",
        title: "Mobile",
    },
    {
        field: "nationality",
        title: "Nationality",
        render: rowData => rowData.nationality ? libs.convertCountry(rowData.nationality, "name") : "",
    },
    {
        field: "userType",
        title: "User Type",
    },
];

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});





const logColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'time', label: 'Time', width: 70 },
    { id: 'user', label: 'User' },
    { id: 'status', label: 'Status', width: 70 },
    { id: 'status_message', label: 'Status Info', minWidth: 170 },
    { id: 'remarks', label: 'Remarks', minWidth: 170 },
];

const customerColumns = [
    { id: 'date', label: 'Date', width: 70 },
    { id: 'cpr', label: 'Cpr', width: 70 },
    { id: 'mobile', label: 'Mobile' },
    { id: 'nationality', label: 'Nationality', minWidth: 90 },
    { id: 'faceMatchStatus', label: 'Type', minWidth: 70 },
    { id: 'onboardLocation', label: 'IP Location', minWidth: 70 },
    { id: 'screeningType', label: 'Screening Type', minWidth: 70 },
    { id: 'faceMatchValue', label: 'Face Match Confidence(%)', minWidth: 70 },
    { id: 'userType', label: 'User Type', minWidth: 70 },
    { id: "rejectStatus", label: 'Reject Status' },
    { id: 'actions', label: 'Actions' },
];


export default function CustomerEdit() {
    const [searchKey, setSearchKey] = React.useState("");

    const classes = useStyles();
    const [customersList, setCustomerData] = React.useState([]);
    const [submitting, setSubmitting] = React.useState(false);
    const [respAlert, setRespAlert] = React.useState(null);
    const [snackBarMsg, setSnackBarMsg] = React.useState("");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [showBatchTable, setShowBatchTable] = React.useState(false);


    const [showLoader, setShowLoader] = React.useState(true);
    const [showCustomersTable, setShowCustomersTable] = React.useState(false);
    const [showNameScreen, setShowNameScreen] = React.useState(false);
    const [nameScreen, setNameScreen] = React.useState(null);
    const [customersTable, setCustomersTable] = React.useState(null);
    const [status, setStatus] = React.useState("")
    const [remarks, setRemarks] = React.useState("")

    const [detailsModal, setDetailsModal] = React.useState(false);
    const [detailsView, setDetailsView] = React.useState(null);

    const [editLogModal, setEditLogModal] = React.useState(false);
    const [editLogs, setEditLogs] = React.useState([]);

    const [shuftiData, setShuftiData] = React.useState([]);
    const [shuftiView, setShuftiView] = React.useState(null);
    const [shuftiModal, setShuftiModal] = React.useState(false);
    const [confirmUser, setConfirmUser] = React.useState(false);
    const [minorUser, setMinorUser] = React.useState("")

    const [ipinfoData, setipinfoData] = React.useState({})
    const [ipinfoDataModal, setipinfoDataModal] = React.useState(false)

    const user = useSelector(state => state.user);


    function openSnackBar(msg) {
        //function to tost snackbar message
        setOpenSnack(true);
        setSnackBarMsg(msg);
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }


    React.useEffect(() => {
        fetchCustomers();
        // showLoader()
        // setShowLoader()
        
    }, []);

    const options = {
        exportButton: false,
        showTitle: false,
        actions: true,
        actionsColumnIndex: -1,
        rowStyle: rowData => {
            if (rowData.isKycNameScreenExists) {
                return { backgroundColor: '#d6f0ff' };
            }
            return {};
        }
    };

    const actions = [
        {
            icon: "visibility_icon",
            tooltip: 'View',
            onClick: (event, rowData) => fetchUserDetails(rowData.userID),
            position: "row"
        },
        {
            icon: "image_search_icon",
            tooltip: 'Shufti Data',
            onClick: (event, rowData) => fetchShuftiData(rowData.refShufti),
            position: "row"
        },
        {
            icon: "description_icon",
            tooltip: 'Name Screening Data',
            onClick: (event, rowData) => openNameScreenModal(rowData),
            position: "row"
        },
        {
            icon: "check_circle_icon",
            tooltip: 'Approve',
            onClick: (event, rowData) => confirmApproveCustomer(rowData.cpr),
            position: "row"
        },
        {
            icon: "check_circle_icon",
            tooltip: 'Archive',
            onClick: (event, rowData) => confirmArchiveCustomer(rowData.cpr),
            position: "row"
        }
    ];

    const fetchCustomers = async (resetStatus= false) => {

        let searchKeyParam = ''
        if (searchKey.length !== 0 && resetStatus === false) {
            searchKeyParam = '/' + searchKey
        }

        try {
            let fetchResp = await fetch(`${config.url().baseURL}/get-compliance-users${searchKeyParam}`, {
                method: 'get',
                headers: { 'auth': sessionStorage.getItem('cx_id') }
            })
            const respData = await fetchResp.json();


            // console.log(json);
            // let resp = await axios.get(`${config.url().baseURL}/get-compliance-users`)
            // let respData = resp.data;
            if (!respData.err) {
                setCustomerList(respData.data)
                setShowLoader(false);
                setShowCustomersTable(true);
                // showBatchTable(true)
            } else {
                setCustomerData([]);
                setShowLoader(false);
                // showBatchTable(true)
                setShowCustomersTable(true);
                openSnackBar(respData.msg);
            }
        } catch (error) {
            setCustomerData([]);
            setShowLoader(false);
            setShowCustomersTable(true);
            console.log(error)
            openSnackBar("Unable to process");
        }
    }

    const setCustomerList = (data) => {
        //function to ser customer list

        setCustomersTable(null)
        try {
            setCustomersTable(<MaterialTable components={{
                Container: (props) => <Paper {...props} elevation={0} />,
            }} data={data} columns={columns} options={options} actions={actions} />)
            setCustomerData(data)
        } catch (error) {
            console.log(error)
        }

    }

    const openNameScreenModal = (emp) => {
        //function open employee details edit
        setShowNameScreen(true)
        setShowCustomersTable(false)
        setNameScreen(<NameScreen cpr={emp.cpr} close={closeEdit} type="customer" name={emp.fullname} dob={emp.dateOfBirth} whiteList={true} id={emp._id} nationality={emp.nationality ? libs.convertCountry(emp.nationality, "name") : ""} />)
    }

    const closeEdit = (msg) => {
        //function to close edit form modal
        if (msg) {
            openSnackBar(msg)
        }
        // setNameScreen(null)
        setShowNameScreen(false)
        setShowLoader(false);
        setShowCustomersTable(true);
        // setShowLoader(true)
        // setCustomerData([]);
        // fetchCustomers()
    }

    const fetchEditLog = (id) => {
        //function to fetch company logsData
        setEditLogModal(true);
        axios.get(`${config.url().baseURL}/get-cust-edit-logs/${id}`).then((resp) => {
            let respData = resp.data;
            if (!respData.err) {
                //console.log(JSON.stringify(respData.data[0].log))
                if (respData.data.length !== 0) {
                    setEditLogs(respData.data);
                } else {
                    setEditLogModal(false);
                    openSnackBar("No log found.");
                }

            } else {
                setEditLogModal(false);
                openSnackBar(respData.msg);
            }
        });
    };
    const resetSearch = () => {
        //function to reset search
        setSearchKey("")
        fetchCustomers(true)
    }
  


    const confirmApproveCustomer = (id, type,userType) => {
        //function to confirm user before approve
        setRespAlert(
            <SweetAlert
                warning
                input
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    approveCustomer(id, type,userType)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }} Data
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                Do you really want to approve this user?
            </SweetAlert>)
    }
    const confirmRejectCustomer = (id, type) => {
        // function to confirm user before reject
        setRespAlert(
            <SweetAlert
                warning
                input
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    rejectCustomer(id, type)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }} Data
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                Do you really want to reject this user?
                This is REJECT, not RE-CHECK. Once rejected, KYC will not allow to revoke.
            </SweetAlert>)
    }

    const confirmArchiveCustomer = (id) => {
        //function to confirm user before approve
        setRespAlert(
            <SweetAlert
                warning
                input
                style={{ display: "block" }}
                title="Are you sure?"
                onConfirm={() => {
                    archiveCustomer(id)
                }}
                onCancel={() => {
                    setRespAlert(null)
                }} Data
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnCssClass={classes.button}
                confirmBtnCssClass={classes.button + " " + classes.danger}
            >
                Do you really want to archive this user?
            </SweetAlert>)
    }
    const enableApproveStatus = (statusArg, cpr) => {
        let data = {
            cpr,
            status: statusArg,
            remarks: remarks,
        }
    
        axios.post(`${config.url().baseURL}/change-onboarding-status`, data)
            .then((resp) => {
                let respData = resp.data;
                if (!respData.err) {
                    setRespAlert(
                        <SweetAlert
                            success
                            style={{ display: "block" }}
                            title={respData.msg}
                            onConfirm={() => {
                                setRespAlert("");
                                setRemarks("");
                                window.location.reload(); 
                            }}
                            confirmBtnCssClass={classes.button + " " + classes.success}
                        />
                    );
    
                } else {
                    openSnackBar(respData.msg);
                }
            });
    };

    const getOnboardLocationDetails = (cpr) => {
        try {
            axios.post(`${config.url().baseURL}/get-onboard-location-details`,{ cpr: cpr })

                .then((resp) => {
                    let respData = resp?.data;
                    if (!respData.err) {
                        setipinfoData(respData.data);
                        setipinfoDataModal(true);
                    } else {
                        //openSweetAlert("No Details Found")
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        }
    }
    

    const approveCustomer = async (id, type,userType) => {
        //function to approve customer 
        try {
            let payload = {}
            let url = ``
            let respData
            if (userType == "GPW ONBOARDED") {
                url = `${config.url().baseURL}/change-onboarding-status`
                payload = { status: "APPROVED", cpr: id }
                respData = (await axios.post(url, payload)).data
            } else {
                respData = (await axios.post(`${config.url().baseURL}/compliance-approve/${id}`)).data
            }
            if (!respData.err) {
                setRespAlert(
                    <SweetAlert
                        success
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null)
                            fetchCustomers()
                            
                        }}
                        onCancel={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    >
                    </SweetAlert>)
            } else {
                setRespAlert(null)
                setRespAlert(
                    <SweetAlert
                        danger
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                        onConfirm={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                    >
                    </SweetAlert>)
            }
        } catch (error) {
            openSnackBar("Unable to process.");
        }

    }
    const rejectCustomer = async (id, type) => {
        // function to reject customer 
        try {
            let payload = {}
            let url = ``
            let respData
            if (type == true) {
                url = `${config.url().baseURL}/change-onboarding-status`
                payload = { status: "REJECTED", cpr: id }
                respData = (await axios.post(url, payload)).data
            } else {
                respData = (await axios.post(`${config.url().baseURL}/compliance-reject/${id}`)).data
            }
            if (!respData.err) {
                setRespAlert(
                    <SweetAlert
                        success
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null)
                            fetchCustomers()
                        }}
                        onCancel={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    >
                    </SweetAlert>)
            } else {
                setRespAlert(null)
                setRespAlert(
                    <SweetAlert
                        danger
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                        onConfirm={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                    >
                    </SweetAlert>)
            }
        } catch (error) {
            openSnackBar("Unable to process.");
        }

    }

    const archiveCustomer = async (id) => {
        //function to approve customer 
        try {
            let payload = {}
            let url = ``
            let respData

            url = `${config.url().baseURL}/change-onboarding-status`
            payload = { status: "ARCHIVED", cpr: id }
            respData = (await axios.post(url, payload)).data

            if (!respData.err) {
                setRespAlert(
                    <SweetAlert
                        success
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        onConfirm={() => {
                            setRespAlert(null)
                            fetchCustomers()
                        }}
                        onCancel={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                        confirmBtnCssClass={classes.button + " " + classes.success}
                    >
                    </SweetAlert>)
            } else {
                setRespAlert(null)
                setRespAlert(
                    <SweetAlert
                        danger
                        input
                        style={{ display: "block" }}
                        title={respData.msg}
                        confirmBtnCssClass={classes.button + " " + classes.danger}
                        onConfirm={() => {
                            setRespAlert(null)
                        }}
                        confirmBtnText="Ok"
                    >
                    </SweetAlert>)
            }
        } catch (error) {
            openSnackBar("Unable to process.");
        }

    }

    const fetchShuftiData = async (shuftiRef) => {
        setShuftiModal(true);
        setShowCustomersTable(false)
        setShuftiView(<ShuftiDetails shuftiRef={shuftiRef} snack={openSnackBar} back={goBack} />)
    };

    const fetchShuftiMinor = async (cpr) => {
        setShuftiModal(true);
        setShowCustomersTable(false)
        setShuftiView(<ShuftiDetailsV2 cpr={cpr} snack={openSnackBar} back={goBack} />)
    }

    const goBack = () => {
        //function to go back
        setShowCustomersTable(true)
        setDetailsModal(false);
        setShuftiModal(false)
        setDetailsView(null)
        setShuftiView(null)
    }

    const fetchUserDetails = (id) => {
        //function to fetch details of a customer
        setDetailsModal(true);
        setShowCustomersTable(false)
        setDetailsView(<CustomerDetails id={id} snack={openSnackBar} back={goBack} />)
    };

    // const openSweetAlert = (msg) => {
    //     handleClose()
    //     setRespAlert(
    //         <SweetAlert
    //             warning
    //             style={{ display: "block" }}
    //             title={msg}
    //             onConfirm={() => {
    //                 setRespAlert(null)
    //             }}
    //             confirmBtnCssClass={classes.button + " " + classes.warning}
    //         />
    //     )
    // }

    // const handleClose = () => {
    //     setOpenCheckerModal(false);
    //     setOpenMakerModal(false)
    //     setRemarksModal(false)
    // };

    let service = user.services.find(service => service.name == "Onboarding Queue")


    return (
        <>
            {respAlert}
            <Collapse in={showLoader} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
            </Collapse>
            <Collapse in={showCustomersTable} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={12}>


                        {/* <GridItem xs={12} sm={4 } md={12}>
                            <Collapse
                                in={showBatchTable}
                                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                                {...(true ? { timeout: 500 } : {})}> */}

                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={6}>
                                <Paper elevation={1} className={classes.addDeptContainer}>
                                    <InputBase
                                        id="cusSearch"
                                        autoFocus={true}
                                        fullWidth
                                        type="search"
                                        className={classes.input}
                                        placeholder="Search"
                                        inputProps={{

                                            "aria-label": "Search",
                                            onChange: (event) => { setSearchKey(event.target.value) },
                                            onBlur: (event) => { setSearchKey(event.target.value.trim()) },
                                            value: searchKey,
                                            onKeyDown: (e) => {
                                                if (e.key == "Enter") {
                                                    fetchCustomers()
                                                }
                                            }
                                        }}
                                    />
                                    {!submitting ? (
                                        <Button submit className={classes.addDeptButton}
                                            onClick={() => fetchCustomers()}
                                            size="lg" color="primary">
                                            <SearchIcon fontSize="medium" />
                                        </Button>
                                    ) : (
                                        <BounceLoader color="#004fa4" loading={true} css={override} size={50} />
                                    )}
                                </Paper>
                                <Tooltip placement="bottom" title="Reset">
                                    <IconButton className={classes.addDeptButton}
                                        onClick={() => resetSearch()}
                                        size="lg" color="primary">
                                        <RotateLeftIcon fontSize="medium" />
                                    </IconButton>
                                </Tooltip>
                            </GridItem>
                        </GridContainer>

                        {/* </Collapse>
                        </GridItem> */}
                        {customersList.length ?
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Onboarding Queue</h4>
                                </CardHeader>

                                <CardBody>

                                    <TableContainer className={classes.container}>
                                        <MuiTable stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {customerColumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {customersList.map((row, key) => {
                                                    return (
                                                        <TableRow style={row.isKycNameScreenExists ? { background: "#d6f0ff" } : { background: "" }} hover role="checkbox" tabIndex={-1} key={key}>
                                                            <TableCell>
                                                                {moment(row.updatedAt).format("DD/MM/YYYY")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.cpr}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.mobile}
                                                            </TableCell>
                                                            <TableCell>
                                                                {libs.convertCountry(row.nationality, "name")}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.minorUser ? <Chip
                                                                    size="small"
                                                                    style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px" }}
                                                                    label="Minor"
                                                                /> : <Chip
                                                                    size="small"
                                                                    style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px" }}
                                                                    label="Regular"
                                                                />}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.onboardLocation ? row.onboardLocation : null}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row.screeningTypes && row.screeningTypes.length > 0?
                                                                    row.screeningTypes.length === 1 ? row.screeningTypes[0] 
                                                                    : (row.screeningTypes[0]+' and '+row.screeningTypes[1])
                                                                    : ''
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.faceMatchValue ? row.faceMatchValue : null}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.userType}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.rejectStatus ? <Chip
                                                                    size="small"
                                                                    style={{ backgroundColor: "green", color: "white", textAlign: "center", width: "90px" }}
                                                                    label="True"
                                                                /> : <Chip
                                                                    size="small"
                                                                    style={{ backgroundColor: "#f05945", color: "white", textAlign: "center", width: "90px" }}
                                                                    label="False"
                                                                />}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Tooltip placement="top" title="View">
                                                                    <IconButton
                                                                        onClick={() => fetchUserDetails(row.userID)}
                                                                        style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                        aria-label="Shufti log">
                                                                        <VisibilityIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {row.userType=="Shufti Initiated Signup"&&
                                                                <Tooltip placement="top" title="Shufti Data">
                                                                    {!row.minorUser ? (
                                                                        <IconButton
                                                                            onClick={() => fetchShuftiData(row.refShufti, row?.minorUser, row?.cpr)}
                                                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                            aria-label="Shufti log"
                                                                        >
                                                                            <ImageSearchIcon />
                                                                        </IconButton>
                                                                    ) : (
                                                                        <IconButton
                                                                            onClick={() => fetchShuftiMinor(row.cpr)}
                                                                            style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                            aria-label="Shufti log"
                                                                        >
                                                                            <ImageSearchIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Tooltip>
                                                }
                                                                {
                                                                    (row?.rejectStatus != true) ?
                                                                        <Tooltip placement="top" title="Name Screening Data">
                                                                            <IconButton
                                                                                onClick={() => openNameScreenModal(row)}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="Shufti log">
                                                                                <DescriptionIcon />
                                                                            </IconButton>
                                                                        </Tooltip> : ""
                                                                }
                                                                {
                                                                    (row?.rejectStatus != true) ?
                                                                        <Tooltip placement="top" title="Approve">
                                                                            <IconButton
                                                                                onClick={() => confirmApproveCustomer(row.cpr, row?.minorUser,row?.userType)}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="Shufti log">
                                                                                <CheckCircleIcon />
                                                                            </IconButton>
                                                                        </Tooltip> : ""
                                                                }
                                                                {
                                                                    (row?.rejectStatus != true) ?
                                                                        <Tooltip placement="top" title="Reject">
                                                                            <IconButton
                                                                                onClick={() => confirmRejectCustomer(row.cpr, row?.minorUser)}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="Shufti log">
                                                                                <CancelIcon />
                                                                            </IconButton>
                                                                        </Tooltip> : ""
                                                                }
                                                                {
                                                                    (row?.userType == "GPW ONBOARDED" && row?.rejectStatus != true) ?
                                                                        <Tooltip placement="top" title="Archive">
                                                                            <IconButton
                                                                                onClick={() => confirmArchiveCustomer(row.cpr)}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="Shufti log">
                                                                                <ArchiveIcon />
                                                                            </IconButton>
                                                                        </Tooltip> : ""
                                                                }
                                                                {
                                                                    (row?.userType === "GPW ONBOARDED" && row?.rejectStatus !== true && service.type === "checkerTwo" && status !== "RECHECK") ? (
                                                                        <Tooltip placement="top" title="Recheck">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    enableApproveStatus("RECHECK", row.cpr);
                                                                                }}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="Recheck"
                                                                            >
                                                                                <BorderColorIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : null
                                                                }
                                                                {
                                                                    (row?.onboardLocation != "" && row.onboardLocation!==undefined) ?
                                                                        <Tooltip placement="top" title="IP Details">
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    getOnboardLocationDetails(row.cpr);
                                                                                }}
                                                                                style={{ marginTop: "-10px", marginBottom: "-10px" }}
                                                                                aria-label="IP Details">
                                                                                <LocationOnIcon />
                                                                            </IconButton>
                                                                        </Tooltip> : ""
                                                                }


                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </MuiTable>
                                    </TableContainer>

                                </CardBody>
                            </Card>
                            : <h3>No Pending Users</h3>}
                    </GridItem>
                </GridContainer>
            </Collapse>
            <Collapse in={showNameScreen} style={{ transformOrigin: "0 0 0", textAlign: "center" }} {...(true ? { timeout: 500 } : {})}>
                <Card>
                    <CardBody>
                        {nameScreen}
                    </CardBody>
                </Card>
            </Collapse>
            <Collapse in={detailsModal} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                {detailsView}
            </Collapse>
            <Collapse in={shuftiModal} style={{ transformOrigin: "0 0 0" }} {...(true ? { timeout: 500 } : {})}>
                {shuftiView}
            </Collapse>
            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={editLogModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setEditLogModal(false);
                    setEditLogs([]);
                }}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h4 className={classes.modalTitle}>Edit Log</h4>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    <TableContainer className={classes.container}>
                        <MuiTable stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {logColumns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {editLogs.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key}>
                                            <TableCell>
                                                {moment(row.date).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell>
                                                {moment(row.date).format("HH:MM:SS")}
                                            </TableCell>
                                            <TableCell>
                                                {row.user ? row.user.firstName + " " + row.user.lastName : null}
                                            </TableCell>
                                            <TableCell>
                                                {row.status}
                                            </TableCell>
                                            <TableCell>
                                                {row.status_message}
                                            </TableCell>
                                            <TableCell>
                                                {row.remarks}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </MuiTable>
                    </TableContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooterCenter}>
                    <Button
                        onClick={() => {
                            setEditLogModal(false);
                            setEditLogs([]);
                        }}
                        color="danger"
                        size="sm">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                classes={{
                    root: classes.left + " " + classes.modalRoot,
                    paper: classes.companyStatusLogModal,
                }}
                open={ipinfoDataModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    setipinfoDataModal(false);
                    setipinfoData([]);
                }}
                maxWidth="xl"
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <h3>IP Location Details</h3>
                </DialogTitle>
                <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
                    {ipinfoData ? ( <>
                                        <p>IP: {ipinfoData.ip}</p>
                                        <p>Country: {ipinfoData.country}</p>
                                        <p>Region: {ipinfoData?.additionalInfo?.region}</p>
                                        <p>City: {ipinfoData?.additionalInfo?.city}</p>
                                        <p>Org: {ipinfoData?.additionalInfo?.org}</p>
                                        <p>Postal: {ipinfoData?.additionalInfo?.postal}</p>
                                        <p>Timezone: {ipinfoData?.additionalInfo?.timezone}</p>   
                                        <p>Latitude: {ipinfoData?.additionalInfo?.loc?.split(",")[0]??""}</p>
                                        <p>Longitude: {ipinfoData?.additionalInfo?.loc?.split(",")[1]??""}</p>
                                        </>                                 
                                    ): (
                                        <> 
                                        <p>No Details found</p>
                                        </>   
                                    )}
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        onClick={() => {
                            setipinfoDataModal(false);
                            setipinfoData([]);
                        }}
                        color="danger"
                        size="sm"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            
            < Snackbar
                className={classes.snackbar}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={openSnack}
                autoHideDuration={6000}>
                <Alert
                    onClose={() => {
                        setOpenSnack(false);
                        setSnackBarMsg("");
                    }}
                    severity="error">
                    {snackBarMsg}
                </Alert>
            </Snackbar>
        </>
    );
}