import * as React from 'react'
import axios from 'axios'
import { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Collapse from '@material-ui/core/Collapse'
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import PropagateLoader from 'react-spinners/PropagateLoader'
import MUIDataTable from 'mui-datatables'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import Tooltip from '@material-ui/core/Tooltip';
import { saveAs } from 'file-saver';
import { css } from "@emotion/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import config from 'config'
import { Chip, Modal, TableCell, TableRow, TextField, Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dropzone from 'react-dropzone'
import { Grid } from 'react-loader-spinner'
import moment from 'moment'
import CardFooter from 'components/Card/CardFooter'
import { isNonNullChain } from 'typescript'
const override = css`
    display: block;
    margin: 32px auto;
    border-color: red;
  `;

export default function CouponTable(props) {
    const [showLoader, setShowLoader] = useState(true);
    const [showRewardTbale, setShowRewardTable] = useState(false)
    const [couponList, setCouponList] = useState([])
    const [open, setOpen] = useState(false)
    const [recordName, setRecordName] = useState("")
    const [validFrom, setValidFrom] = useState("")
    const [validTo, setValidTo] = useState("")
    const [respData, setRespData] = useState("")
    const [rewardType, setRewardType] = useState("");
    const [couponCodeType, setCouponCodeType] = useState("");
    const [productId, setProductId] = useState("");
    const [merchantName, setMerchantName] = useState("");
    const [denomination, setDenomination] = useState("");
    //    percentage
    const [currency, setCurrency] = useState("");
    const [redemptionType, setRedumptionType] = useState("");
    const [redumptionAccepted, setRedumptionAccepted] = useState("");
    const [redumptionAcceptedFormat, setRedumptionAcceptedFormat] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState("")
    const [openSnack, setOpenSnack] = useState(false)
    const [snackBarMsg, setSnackBarMsg] = useState("")
    const [snackBarType, setSnackBarType] = useState("error")
    const [description, setDescription] = useState("")
    const [serialNo, setSerialNo] = useState("")
    const [count, setCount] = useState("")
    const [subming, setSubmitting] = useState(false)
    const [uploadedFile, setUploadedFile] = useState(null);
    const [rewardDetails, setRewardDetails] = useState([])

    const formData = new FormData();



    const options = {
        filter: true,
        sort: true,
        selectableRows: 'none',
        rowsPerPageOptions: [10, 20, 50],
        customRowRender: (data, dataIndex, rowIndex) => {
            console.log(data[7]);
            const rowColor = data[7] === true ? 'rgb(252, 240, 240)' : 'rgb(240, 252, 240)';
            return (
                <TableRow style={{ backgroundColor: rowColor }}>
                    {data.map((item, colIndex) => (
                        <TableCell key={colIndex}>{item}</TableCell>
                    ))}
                </TableRow>
            );
        },
    };

    const handleAddClick = () => {
        setOpen(true)
    }
    const columns = [

        {
            name: "couponSetId",
            label: "Coupon Id",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "allocatedCount",
            label: "Allocated Count",
            options: {
                filter: true,
                sort: true
            }

        },

        {
            name: "unallocatedCount",
            label: "Un Allocated Count",
            options: {
                filter: true,
                sort: true
            }

        },

        {
            name: "redeemedCount",
            label: "Redeemed Count",
            options: {
                filter: true,
                sort: true
            }

        },
        {
            name: "totalCount",
            label: "Total Count",
            options: {
                filter: true,
                sort: true
            }

        },


        {
            name: "validFrom",
            label: "Valid From",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    const formattedDate = moment(value).format('DD-MM-YYYY');
                    return formattedDate;
                },
            }
        },
        {
            name: "validTo",
            label: "Validity To",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    const formattedDate = moment.utc(value).format('DD-MM-YYYY');
                    return formattedDate;
                },
            }
        },
        {
            name: "expired",
            label: "expired",

            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRender: (value, tableMeta) => {
                    // console.log(tableMeta);
                    const rowIndex = tableMeta.rowIndex;
                    const rowInfo = couponList[rowIndex];
                    const expiredText = value
                    // ? 'True' : 'False';
                    const color = value ? 'red' : 'green';
                    return expiredText;
                },

            }

        },
        {
            name: "action",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const rowInfo = couponList[rowIndex];

                  

                    return (
                        <div>
                            {
                                (rowInfo.downloadableCouponsPresent === true )?
                                <Tooltip>
                                <span
                                    style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: '-55px' }}
                                    onClick={() => downloadExcel(tableMeta.rowData[0] ? tableMeta.rowData[0] : null)}
                                >
                                    <CloudDownloadIcon />
                                </span>
                            </Tooltip> : ""
                            }
                        </div>
                    );
                },
            },
        }

    ]



    const useStyles = makeStyles((theme) => ({
        cardCategoryWhite: {
            "&,& a,& a:hover,& a:focus": {
                color: "rgba(255,255,255,.62)",
                margin: "0",
                fontSize: "14px",
                marginTop: "0",
                marginBottom: "0",
            },
            "& a,& a:hover,& a:focus": {
                color: "#FFFFFF",
            },
        },
        cardTitleWhite: {
            color: "#FFFFFF",
            marginTop: "0px",
            minHeight: "auto",
            fontWeight: "300",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            marginBottom: "3px",
            textDecoration: "none",
            float: "left",
            display: "inlineBlock",
            "& small": {
                color: "#777",
                fontSize: "65%",
                fontWeight: "400",
                lineHeight: "1",
            },
        },
        addButton: {
            float: "right !important",
            display: "inlineBlock",
            marginTop: "0 !important",
            marginBottom: "0 !important",
        },
        addDeptContainer: {
            height: "49px",
            padding: "2px 4px",
            display: "flex",
            alignItems: "center",
            marginBottom: "5px !important",
        },
        addDeptButton: {
            marginRight: "-5px !important",
        },
        input: {
            flex: 1,
            height: "45px",
            marginLeft: "15px",
        },
        searchHead: {
            marginTop: "-5px",
            marginBottom: "1px",
            color: "#144b85",
            fontWeight: "400"
        },
        companyStatusLogModal12: {
            margin: "auto",
            minWidth: "800px",
        },
        dialogContainer: {
            display: "flex",
        },
        inputPassword: {
            flex: 1,
            marginTop: "10px",
            height: "45px",

            paddingLeft: "10px",
        },
        nextButton: {
            marginLeft: "150px"
        },
        passwordHead: {
            fontWeightL: "500",
            marginBottom: "0px",
            color: "#ffffff"
        },
        indicator: {
            backgroundColor: 'white',
        },


    }));
    const classes = useStyles();

    useEffect(() => {
        fetchCoupon()
    }, [])


    function openSnackBar(msg, success) {
        setOpenSnack(true)
        if (success) {
            setSnackBarType("success")
        } else {
            setSnackBarType("error")
        }
        setSnackBarMsg(JSON.stringify(msg).replace(/^"(.*)"$/, '$1'));
        setTimeout(function () {
            setOpenSnack(false);
            setSnackBarMsg("");
        }, 5000);
    }
    const validateCoupon = () => {
        if (!count) {
            props.snack("Please enter Count")
            return
        } else if (respData.couponCodeType !== "COMMON" && !uploadedFile) {
            props.snack("Please Upload Excel")
            return
        } else if (respData.couponCodeType === "COMMON" && !serialNo) {
            props.snack("Please Enter SerialNo")
            return
        } else if (!validFrom || !validTo) {
            props.snack("Please Select date")
            return
        }
        addCoupon()
    }
    const downloadExcel = (couponSetId) => {
        try {
            let data = {
                couponSetId,
                productId: productId,
            };
            axios
                .post(`${config.url().app_URL}/CXPortal/v13/deleteAndDownloadCoupons`, data, {
                    responseType: 'blob',
                })
                .then(async (resp) => {
                    if (!resp.data.err) {
                        const blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        saveAs(blob, 'Unused Coupon.csv');
                    } else {
                        openSnackBar(resp.data.msg);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    openSnackBar('Unable to process.');
                });
        } catch (error) {
            console.error(error);
            openSnackBar('Unable to process.');
        }
    };


    const addCoupon = async () => {
        try {

            setSubmitting(true)
            formData.append('rewardType', rewardType)
            formData.append('termsAndConditions', termsAndConditions)
            formData.append('redemptionType', redemptionType)
            formData.append('redemptionAcceptedFormat', redumptionAcceptedFormat)
            formData.append('redemptionAccepted', redumptionAccepted)
            formData.append('productId', productId)
            formData.append('merchantName', merchantName)
            formData.append('description', description)
            formData.append('denomination', denomination)
            formData.append('currency', currency)
            formData.append('couponCodeType', couponCodeType)
            formData.append("validFrom", validFrom)
            formData.append("validTo", validTo)
            formData.append("count", count)

            formData.append("file", uploadedFile)
            formData.append("serialNo", serialNo)

            const resp = await axios.post(
                `${config.url().app_URL}/CXPortal/v13/addCustomRewards`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (resp.data.err) {
                props.snack(resp.data.msg)
            }
            setOpen(false)
            fetchCoupon()
        } catch (err) {
            console.log(err);
            props.snack("Some thing Unexpected Happened")
        } finally {
            setOpen(false)
            setSubmitting(false)
        }

    }

    const fetchCoupon = async () => {
        try {

            const resp = await axios.post(`${config.url().app_URL}/CXPortal/v13/customRewardDetail`, { productId: props.id })
            if (resp.data.err) {
                props.snack(resp.data.msg)
                return
            }
            const coupon = resp.data?.data
            setRespData(coupon)
            setRewardType(coupon?.rewardType)
            setCouponCodeType(coupon?.couponCodeType)
            setCurrency(coupon?.currency)
            setDenomination(coupon?.denomination)
            setDescription(coupon?.description)
            setMerchantName(coupon?.merchantName)
            setProductId(coupon?.productId)
            setRedumptionAccepted(coupon?.redemptionAccepted)
            setRedumptionAcceptedFormat(coupon?.redemptionAcceptedFormat)
            setRedumptionType(coupon?.redemptionType)
            setDescription(coupon?.description)
            setTermsAndConditions(coupon?.termsAndConditions)
            setCouponList(coupon?.couponData)
            if (resp.data.err) {
                props.snack(resp.data.msg)
            }
            setShowLoader(false)
            setShowRewardTable(true)
            // setRewardDetails()

        } catch (err) {
            console.log(err);
            props.snack("Something Unexpected Happened")

        }
    }


    return (
        <div>

            <GridContainer justify='center'>

                <GridItem xs={12} sm={12} md={12}>

                    <Modal open={open} onClose={() => setOpen(false)}>
                        <Card style={{ width: 1000, margin: '0 auto', marginTop: 100, boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                            <CardBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40 }}>
                                <h2 style={{ textAlign: 'center', marginBottom: 30, fontSize: 24 }}>Add New Coupon</h2>
                                <div style={{ marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                                    <div style={{ marginRight: 10 }}>
                                        <TextField label="Count *" variant="outlined" fullWidth onChange={(e) => { setCount(e.target.value) }} value={count} />
                                    </div>
                                    {respData.couponCodeType !== "COMMON" ? (
                                        <>
                                            <Dropzone accept=".xlsx, .xls" onDrop={(acceptedFiles) => setUploadedFile(acceptedFiles[0])}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()} style={{ padding: 10, textAlign: 'center', cursor: 'pointer', border: '2px dashed #aaa', borderRadius: 8 }}>
                                                        <input {...getInputProps()} />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <CloudUploadIcon color="primary" style={{ marginRight: 10 }} />
                                                            <span style={{ fontSize: 14, fontWeight: 'bold', color: '#555' }}>Upload Excel File</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </>
                                    ) : (
                                        <TextField label="Serial No *" variant="outlined" fullWidth onChange={(e) => { setSerialNo(e.target.value) }} value={serialNo} />
                                    )}
                                </div>
                                {uploadedFile && (
                                    <div style={{ marginBottom: 10 }}>
                                        Uploaded File: {uploadedFile.name}
                                    </div>
                                )}
                                <div style={{ display: 'flex', marginBottom: 30, justifyContent: 'center' }}>
                                    <TextField
                                        type="date"
                                        style={{ width: 300, marginRight: 20 }}
                                        label="Valid From *"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            autoComplete: 'off',
                                            type: 'date',
                                            min: moment().format('YYYY-MM-DD'), // Set the minimum selectable date as today
                                            onChange: (e) => {
                                                setValidFrom(e.target.value);
                                            },
                                            onBlur: (e) => {
                                                // Handle onBlur event if needed
                                            },
                                        }}
                                        InputProps={{
                                            style: { color: '#555', fontWeight: 'bold' }
                                        }}
                                    />
                                    <TextField
                                        onChange={(e) => setValidTo(e.target.value)}
                                        type="date"
                                        style={{ width: 300 }}
                                        label="Valid To *"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{
                                            autoComplete: 'off',
                                            type: 'date',
                                            min: validFrom,
                                            onBlur: (e) => {
                                                // Handle onBlur event if needed
                                            },
                                        }}
                                        InputProps={{
                                            style: { color: '#555', fontWeight: 'bold' }
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto' }}>
                                    {!subming ? (
                                        <>
                                            <Button variant="contained" color="primary" style={{ marginRight: 10, fontWeight: 'bold', letterSpacing: 1 }} onClick={validateCoupon}>
                                                Submit
                                            </Button>
                                            <Button variant="contained" style={{ backgroundColor: '#f44336', color: '#fff', fontWeight: 'bold', letterSpacing: 1 }} onClick={() => setOpen(false)}>
                                                Cancel
                                            </Button>
                                        </>
                                    ) : <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />}
                                </div>
                            </CardBody>
                        </Card>
                    </Modal>








                    <Collapse
                        in={showLoader}
                        style={{
                            transformOrigin: "0 0 0", textAlign: 'center'
                        }}
                        {...true ? { timeout: 500 } : {}}>
                        <PropagateLoader color="#004fa4" loading={true} css={override} size={15} />
                    </Collapse>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Collapse
                        in={showRewardTbale}
                        style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                        {...true ? { timeout: 500 } : {}}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}> Coupons</h4>
                                <Button
                                    onClick={() => handleAddClick()}
                                    color="secondary" size="sm" className={classes.addButton}>
                                    Add Coupon
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <MUIDataTable data={couponList} columns={columns} options={options} />
                            </CardBody>
                            <CardFooter className={classes.cardFooter}>

                                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px', marginLeft: "43%" }}>

                                    <Button
                                        tranCode="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitButton}
                                        onClick={props.back}
                                    >
                                        Back
                                    </Button>


                                </div>
                            </CardFooter>
                        </Card>
                    </Collapse>
                </GridItem>
            </GridContainer>


        </div>
    )
}
