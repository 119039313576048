import React, { useState, useEffect, useCallback } from 'react';
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Tooltip,
    TablePagination,
    Box,
    Typography,
    Chip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TimelineIcon from '@material-ui/icons/Timeline';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PropagateLoader from 'react-spinners/PropagateLoader';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomerDetailsModal from './components/customerDetailsModal';
import LogsModal from './components/LogsModal'; // Import the LogsModal component
import config from '../../config';
import { showAlert, customerDetailsColumns } from './complanceCustFns';
import Header from './components/Header';

const useStyles = makeStyles((theme) => ({
    headerContainer: {
        marginBottom: theme.spacing(2),
    },
    cardHeader: {
        color: theme.palette.primary.main,
    },
}));

const ComplainceCustomer = () => {
    const classes = useStyles();
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [cpr, setCpr] = useState("");
    const [referenceNumber, setReferenceNumber] = useState("");
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [details, setDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [totalDoc, setTotalDoc] = useState(0);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [referenceNo, setReferenceNo] = useState('');
    const [logsModalOpen, setLogsModalOpen] = useState(false); // State to handle logs modal
    const [logs, setLogs] = useState([]); // State to store logs data
    const [isReload, setIsReload] = useState(false);

    // Temporary state for filter values
    const [tempFromDate, setTempFromDate] = useState("");
    const [tempToDate, setTempToDate] = useState("");
    const [tempCpr, setTempCpr] = useState("");
    const [tempReferenceNumber, setTempReferenceNumber] = useState("");

    const user = useSelector((state) => state.user);
    const handleReload = useCallback((data) => setIsReload(data), []);
    const handleChangePageSize = (event) => {
        setPageSize(+event.target.value);
        setPage(0);
    };
    const handleChangePage = (_, newPage) => setPage(newPage);

    // Fetch data based on the filters
    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);

        const params = { page: page + 1, limit: pageSize, fromDate, toDate, searchKey:referenceNumber };
        const url = `${config.url().appURL}/CXPortal/v20/shufti/compliance`;

        try {
            const response = await axios.post(url, params);
            const data = response.data;

            if (data.err) {
                showAlert('error', 'Error!', 'Error fetching CustomerDetails');
                throw new Error('Error fetching data.');
            }

            setCustomerDetails(Array.isArray(data.data) ? data.data : []);
            setTotalDoc(data.totalDocuments || 0);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to load data.');
        } finally {
            setLoading(false);
        }
    }, [page, pageSize, fromDate, toDate, cpr, referenceNumber]);

    useEffect(() => {
        fetchData();
    }, [page, pageSize, isReload, fetchData]);

    const handleViewDetails = async (row) => {
        setLoading(true);
        setDetails(null);

        try {
            const response = await axios.post(`${config.url().appURL}/CXPortal/v20/shufti/compliance/detialView`, {
                refShufti: row.referenceNumber,
            });

            const data = response.data;

            if (data.err) {
                showAlert('error', 'Error!', 'Error fetching details');
                throw new Error('Error fetching details.');
            }

            setReferenceNo(row.referenceNumber);
            setDetails(data.data);
            setDetailsModalOpen(true);
        } catch (error) {
            console.error('Error fetching details:', error);
            setError('Failed to load details.');
        } finally {
            setLoading(false);
        }
    };

    const handleViewLogs = async (row) => {
        setLoading(true);
        setLogs([]);

        try {
            const response = await axios.post(`${config.url().appURL}/CXPortal/v20/shufti/compliance/logs`, {
                referenceNo: row.referenceNumber,
            });

            const data = response.data;

            if (data.err) {
                showAlert('error', 'Error!', data.msg);
                throw new Error('Error fetching logs.');
            }

            setLogs(data.data || []);
            setLogsModalOpen(true);
        } catch (error) {
            console.error('Error fetching logs:', error);
            setError(null);
        } finally {
            setLoading(false);
        }
    };

    // Handle filter changes temporarily
    const handleChangeTempFilter = (event, type) => {
        switch (type) {
            case "fromDate":
                setTempFromDate(event.target.value);
                break;
            case "toDate":
                setTempToDate(event.target.value);
                break;
            case "cpr":
                setTempCpr(event.target.value);
                break;
            case "referenceNumber":
                setTempReferenceNumber(event.target.value);
                break;
            case "reset":
                setTempFromDate("");
                setTempToDate("");
                setTempReferenceNumber("");
                setTempCpr("");
                setReferenceNumber("");
                setFromDate("");
                setToDate("");
                setCpr("");
                break;
        }
    };

    const handleSearch = () => {
        setFromDate(tempFromDate);
        setToDate(tempToDate);
        setReferenceNumber(tempReferenceNumber);
    };

    return (
        <div>
            <div className={classes.headerContainer}>
                <Header
                    onChangeFilter={handleChangeTempFilter}
                    fromDate={tempFromDate}
                    toDate={tempToDate}
                    referenceNumber={tempReferenceNumber}
                    filterEkyc={handleSearch}
                />
            </div>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="flex-start" height="100vh" paddingTop={2}>
                    <PropagateLoader color="#004fa4" loading={true} size={15} />
                </Box>
            ) : error ? (
                <Box display="flex" justifyContent="center" alignItems="flex-start" height="100vh" paddingTop={2}>
                    <Typography>{error}</Typography>
                </Box>
            ) : (
                <Card>
                    <CardHeader color="primary" className={classes.cardHeader}>
                        <h4>Ekyc Report</h4>
                    </CardHeader>

                    <CardBody>
                        <TableContainer>
                            <MuiTable stickyHeader aria-label="compliance table">
                                <TableHead>
                                    <TableRow>
                                        {customerDetailsColumns.map((column) => (
                                            <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {customerDetails.length === 0 ? (
                                        <TableRow>
                                            <TableCell colSpan={customerDetailsColumns.length} align="center">
                                                No details available
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        customerDetails.map((row, key) => (
                                            <TableRow key={key} hover style={{ backgroundColor: row.action === 'REJECTED' ? '#f8d7da' : '' }}>
                                                <TableCell>{moment(row.createdAt).format('DD/MM/YYYY hh:mm:ss')}</TableCell>
                                                <TableCell>{row.cpr}</TableCell>
                                                <TableCell>{row.referenceNumber}</TableCell>
                                                <TableCell>{row.walletType}</TableCell>
                                                <TableCell>
                                                    {row.reasons.map((reason, index) => (
                                                        <Chip key={index} label={reason} style={{ margin: '2px' }} />
                                                    ))}
                                                </TableCell>
                                                <TableCell>{row.action === "REJECTED" ? "RE-DO" : ""}</TableCell>
                                                <TableCell>{row.verificationType}</TableCell>
                                                <TableCell>
                                                    <Tooltip title="View">
                                                        <IconButton
                                                            onClick={() => handleViewDetails(row)}
                                                            aria-label="View details"
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Logs">
                                                        <IconButton
                                                            aria-label="Logs"
                                                            onClick={()=>handleViewLogs(row)}
                                                        >
                                                            <TimelineIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    )}
                                </TableBody>
                            </MuiTable>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={totalDoc}
                            rowsPerPage={pageSize}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangePageSize}
                        />
                    </CardBody>
                </Card>
            )}
            {detailsModalOpen && (
                <CustomerDetailsModal open={detailsModalOpen} referenceNo={referenceNo} onClose={() => setDetailsModalOpen(false)} details={details} setIsReload={setIsReload} name={user?.firstName+" "+user?.lastName} email={user.email}/>

                //     <CustomerDetailsModal
                //     referenceNo={referenceNo}
                //     open={detailsModalOpen}
                //     onClose={() => setDetailsModalOpen(false)}
                //     details={details}
                // />
            )}
            {logsModalOpen && (
                <LogsModal open={logsModalOpen} onClose={() => setLogsModalOpen(false)} logs={logs} />
            )}
        </div>
    );
};

export default ComplainceCustomer;
