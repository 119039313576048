import React from "react";
import config from "config.js"
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { css } from "@emotion/core";
import PropagateLoader from "react-spinners/PropagateLoader";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import moment from "moment";
import JSONPretty from 'react-json-pretty';

const override = css`
  display: block;
  margin: 32px auto;
  border-color: red;
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    tabContent: {
        maxHeight: "60vh"
    },
    logPaper: {
        margin: "15px",
        padding: "15px"
    }
}));

export default function TransactionDetails(props) {
    const classes = useStyles();
    const [transactionDetails, setTransactionDetails] = React.useState([]);
    const [noTransactionDetails, setNoTransactionDetails] = React.useState(false);

    React.useEffect(() => {
        if (props.transactionID !== null) {
            setTransactionDetails([]);
            setNoTransactionDetails(false);
            getTransactionDetails(props.transactionID)
        } else {
            setTransactionDetails([]);
            setNoTransactionDetails(false);
        }
    }, []);



    const getTransactionDetails = (id) => {
        //function to fetch netsys logs for a customer
        if (transactionDetails.length == 0) {
            let url = props.type == "netSys" ? `${config.url().transactionMSURL}/paymentMicroServices/v1/getSingleTransaction` : `${config.url().transactionMSURL}/paymentMicroServices/v1/remittance/getSingleTransaction`
            axios.post(url, { transactionID: id })
                .then((resp) => {
                    let respData = resp.data;
                    if (!respData.err) {
                        console.log(JSON.stringify(respData.data.amountDetail))

                        setTransactionDetails(respData.data)
                    } else {
                        setTransactionDetails([]);
                        setNoTransactionDetails(true)
                    }
                })
                .catch((err) => {
                    setTransactionDetails([]);
                    setNoTransactionDetails(true)
                });
        }
    }


    return (
        <div className={classes.root}>
            <Collapse
                in={noTransactionDetails}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <h4>No Transaction details Found.</h4>
            </Collapse>
            <Collapse
                in={!noTransactionDetails}
                style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                {...(true ? { timeout: 500 } : {})}>
                <Collapse
                    in={transactionDetails.length == 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "center" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <PropagateLoader color="#004fa4" loading={true} css={override} size={10} />
                </Collapse>
                <Collapse
                    in={transactionDetails.length !== 0}
                    style={{ transformOrigin: "0 0 0", textAlign: "left" }}
                    {...(true ? { timeout: 500 } : {})}>
                    <div className={classes.logContainer}>

                        {transactionDetails.transactionID ? (
                            <>
                                Transaction ID :{transactionDetails.transactionID}
                                <br />
                            </>
                        ) : null}

                        Wallet Status :{transactionDetails.status ? "Success" : "Failure"}
                        <br />
                        {props.type == "netSys" ?
                            (<>
                                NetSys Status :{transactionDetails.netsysStatus ? "Success" : "Failure"}
                                <br />
                            </>
                            ) : null}
                        {props.type == "avenues" ?
                            (<>
                                Acknowledgment Status :{transactionDetails.ackStatus ? "Success" : "Failure"}
                                <br />
                            </>
                            ) : null}
                        {transactionDetails.timeStamp_created ? (
                            <>
                                Created Date & Time : {`${moment(transactionDetails.timeStamp_created).format("DD/MM/YYYY")} ${moment(transactionDetails.timeStamp_created).format("h:mm:ss A")}`}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.timeStamp_updated ? (
                            <>
                                Updated Date & Time : {`${moment(transactionDetails.timeStamp_updated).format("DD/MM/YYYY")} ${moment(transactionDetails.timeStamp_updated).format("h:mm:ss A")}`}
                                <br />
                            </>
                        ) : null}

                        {transactionDetails.amountDetail ? (
                            <>
                                Total Amount : {transactionDetails.amountDetail.Total_amount}
                                <br />
                                Amount : {transactionDetails.amountDetail.amount}
                                <br />
                                Fee : {transactionDetails.amountDetail.fee}
                                <br />
                                Tax : {transactionDetails.amountDetail.tax}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.paymentMode ? (
                            <>
                                Payment Mode : {transactionDetails.paymentMode}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.paymentType ? (
                            <>
                                Payment Type : {transactionDetails.paymentType}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.userID ? (
                            <>
                                User ID : {transactionDetails.userID}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.cpr ? (
                            <>
                                CPR : {transactionDetails.cpr}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.reasonForError ? (
                            <>
                                Reason for error : {transactionDetails.reasonForError}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.netsysTransactionID ? (
                            <>
                                Netsys Transaction ID : {transactionDetails.netsysTransactionID}
                                <br />
                            </>
                        ) : null}
                        {transactionDetails.netsysLog ? (
                            <Accordion  >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Netsys Log</Typography>
                                </AccordionSummary>
                                <AccordionDetails  >
                                    <div style={{ width: "750px !important" }}>
                                        <JSONPretty data={transactionDetails.netsysLog} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ) : null}

                        {transactionDetails.transactionLog ? (
                            <Accordion  >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Transaction Log</Typography>
                                </AccordionSummary>
                                <AccordionDetails  >
                                    <div style={{ width: "750px !important" }}>
                                        <JSONPretty data={transactionDetails.transactionLog} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ) : null}


                        {transactionDetails.acknowledgeLog ? (
                            <Accordion  >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header">
                                    <Typography>Acknowledge Log</Typography>
                                </AccordionSummary>
                                <AccordionDetails  >
                                    <div style={{ width: "750px !important" }}>
                                        <JSONPretty data={transactionDetails.acknowledgeLog} />
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ) : null}

                    </div>
                </Collapse>
            </Collapse>
        </div >
    );
}
