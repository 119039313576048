import React from 'react';
import {
    Grid,
    Paper,
    InputBase,
    Button,
    Tooltip,
    IconButton,
    FormControl,
    TextField,
    InputAdornment,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SearchIcon from '@mui/icons-material/Search';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import config from '../../../config';
import { saveAs } from 'file-saver';


// Styled components for reusability and consistency
const SearchLabel = styled('p')({
    margin: 0,
    color: "#144b85",
    fontWeight: "500",
    fontSize: "0.9rem",
});

const InputContainer = styled(Paper)({
    padding: "4px",
    display: "flex",
    alignItems: "center",
    height: "45px",
    boxShadow: "none",
    border: "1px solid #ccc",
});

const SearchInput = styled(InputBase)({
    flex: 1,
    padding: "5px 10px",
});

const ActionButton = styled(Button)({
    backgroundColor: '#0057b8',
    color: 'white',
    minWidth: '48px',
    height: '45px',
    '&:hover': {
        backgroundColor: '#004494',
    },
});

const DownloadButton = styled(Button)({
    backgroundColor: '#0057b8', // Primary color
    height: '39px',
    '&:hover': {
        backgroundColor: '#004494', // Hover color
    },
    marginLeft: '10px',
    color: 'white', // Ensure text/icon is visible
});

const ResetButtonContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
});

export default function Header(props) {
    const {
        onChangeFilter,
        fromDate,
        toDate,
        referenceNumber,
        filterEkyc,
    } = props;

    // Reset the search filters
    const resetSearch = () => {
        onChangeFilter('', 'reset');
    };

    // Snackbar logic placeholder
    const openSnackBar = (message) => {
        // Implement your snackbar logic here
    };

    // Download Excel file
    const downloadExcel = () => {
        try {
            const data = {
                fromDate,
                toDate,
                searchKey: referenceNumber,
                requestType: "download",
            };

            axios
                .post(`${config.url().appURL}/CXPortal/v20/shufti/compliance`, data, {
                    responseType: 'blob',
                })
                .then((resp) => {
                    if (!resp.data.err) {
                        const blob = new Blob([resp.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        saveAs(blob, 'ekycList.csv');
                    } else {
                        openSnackBar(resp.data.msg);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    openSnackBar('Unable to process.');
                });
        } catch (error) {
            console.error(error);
            openSnackBar('Unable to process.');
        }
    };

    return (
        <div>
            <Grid container spacing={2} alignItems="center">
                {/* From Date Field */}
                <Grid item xs={12} sm={6} md={3}>
                    <SearchLabel>From Date</SearchLabel>
                    <InputContainer>
                        <SearchInput
                            type="date"
                            inputProps={{
                                max: toDate || moment().format('YYYY-MM-DD'), // From Date cannot be in the future
                            }}
                            value={fromDate}
                            onChange={(event) => {
                                onChangeFilter(event, "fromDate");
                            }}
                            onBlur={(event) => {
                                if (moment(event.target.value).isAfter(moment())) {
                                    openSnackBar("Please enter a valid date.");
                                }
                            }}
                        />
                    </InputContainer>
                </Grid>

                {/* To Date Field */}
                <Grid item xs={12} sm={6} md={3}>
                    <SearchLabel>To Date</SearchLabel>
                    <InputContainer>
                        <SearchInput
                            type="date"
                            inputProps={{
                                min: fromDate || "1900-01-01", // To Date must be greater than From Date
                                max: fromDate
                                    ? moment.min(
                                        moment(fromDate).add(3, 'months'), // 3 months from From Date
                                        moment() // Today's date
                                    ).format('YYYY-MM-DD') // Take the earlier of the two dates
                                    : moment().format('YYYY-MM-DD'),
                            }}
                            value={toDate}
                            onChange={(event) => onChangeFilter(event, "toDate")}
                            onBlur={(event) => {
                                if (fromDate && moment(event.target.value).isSameOrBefore(moment(fromDate))) {
                                    openSnackBar("To Date must be greater than From Date.");
                                }
                            }}
                        // disabled={!fromDate} // Disable To Date if no From Date is selected
                        />
                    </InputContainer>
                </Grid>


                {/* Reference Number Field */}
                <Grid item xs={12} sm={6} md={4}>
                    <SearchLabel>Search CPR Or Reference Number</SearchLabel>
                    <InputContainer>
                        <SearchInput
                            placeholder="Enter CPR or Reference Number"
                            value={referenceNumber}
                            onChange={(event) => onChangeFilter(event, 'referenceNumber')}
                        />
                        <Tooltip title="Search">
                            <IconButton
                                onClick={() => filterEkyc({ referenceNumber, fromDate, toDate })}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    color: 'white',
                                    width: 40,
                                    height: 40,
                                    borderRadius: '4px',
                                    marginLeft: '8px',
                                    '&:hover': {
                                        backgroundColor: (theme) => theme.palette.primary.dark,
                                    },
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </InputContainer>
                </Grid>

                {/* Download Button */}
                <Grid item xs={12} sm={6} md={2}>
                    <Tooltip title="Download">
                        <DownloadButton onClick={downloadExcel}>
                            <CloudDownloadIcon fontSize="medium" />
                        </DownloadButton>
                    </Tooltip>
                </Grid>
            </Grid>


            {/* Reset Button */}
            <ResetButtonContainer>
                <Tooltip title="Reset">
                    <IconButton onClick={resetSearch}>
                        <RotateLeftIcon fontSize="medium" />
                    </IconButton>
                </Tooltip>
            </ResetButtonContainer>
        </div>
    );
}
